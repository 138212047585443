import { Card, Timeline, Button, Accordion } from "flowbite-react";
import PageHeader from "../../components/pageHeader";
import school from "../../assets/short-gallery-1.jpeg"
import { useState } from "react";
import CubsBulbuls from "../../components/clubs-list/cubsBulbuls";
import Ync from "../../components/clubs-list/ync";
import Rsp from "../../components/clubs-list/rsp";
import Nss from "../../components/clubs-list/nss";
import TamilDramatics from "../../components/clubs-list/tamilDramatics";
import EnglishDramatics from "../../components/clubs-list/englishDramatics";
import Jrc from "../../components/clubs-list/jrc";
import Choir from "../../components/clubs-list/choir";
import FineArts from "../../components/clubs-list/fineArts";
import HeritageClub from "../../components/clubs-list/heritageClub";
import clubsLogos from "../../assets/clubs-logos.png";
import './clubs.css';


export default function OurClubs() {

    const [selectedContent, setSelectedContent] = useState('cubsBulbuls');

    const content = {
        cubsBulbuls: <CubsBulbuls />,
        jrc: <Jrc />,
        choir: <Choir />,
        rsp: <Rsp />,
        tamilDramatics: <TamilDramatics />,
        englishDramatics: <EnglishDramatics />,
        nss: <Nss />,
        heritageClub: <HeritageClub />,
        fineArts: <FineArts />,
        ync: <Ync />,
      };

    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Our Clubs"} />
            </div>
            <div className="mb-10 mt-10 mobile_clubs_main">
               <div className="flex flex-col gap-10">
                    <div className="image-container overflow-hidden w-[100%] h-[24vmin] mobile_clubs_image">
                        <img src={clubsLogos} alt="image not loaded" className="w-[100%] h-full object-cover" style={{ objectPosition: 'center 50%' }}/>
                    </div>
                    <div className="flex mx-20 hide_clubs">
                        <div className="left-side w-1/4">
                            <div className="list-bar">
                            <ul className="flex flex-col justify-evenly montserrat dark-bluish">
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'cubsBulbuls' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('cubsBulbuls')}>Cubs and Bulbuls</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'jrc' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('jrc')}>Junior Red Cross</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'rsp' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('rsp')}>Road Safety Patrol</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'nss' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('nss')}>National Service Scheme</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'choir' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('choir')}>Choir</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'fineArts' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('fineArts')}>Fine Arts Club</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'heritageClub' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('heritageClub')}>Heritage Club</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'ync' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('ync')}>Youth Nature Club</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'englishDramatics' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('englishDramatics')}>English Dramatics</li>
                            <li className={`border-b  p-4 cursor-pointer transition-colors duration-100 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'tamilDramatics' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('tamilDramatics')}>Tamil Dramatics</li>
                            </ul>
                            </div>
                            
                        </div>
                        <div className="components w-3/4">
                            <div className="mx-20">
                                {content[selectedContent]}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col mx-auto show_mobile_clubs">
                    <Accordion className="custom_accordion">
                        <Accordion.Panel>
                            <Accordion.Title>Cubs and Bulbuls</Accordion.Title>
                            <Accordion.Content>
                                <CubsBulbuls />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Junior Red Cross</Accordion.Title>
                            <Accordion.Content>
                                <Jrc />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Road Safety Patrol</Accordion.Title>
                            <Accordion.Content>
                                <Rsp />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>National Service Scheme</Accordion.Title>
                            <Accordion.Content>
                                <Nss />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Choir</Accordion.Title>
                            <Accordion.Content>
                                <Choir />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Fine Arts Club</Accordion.Title>
                            <Accordion.Content>
                                <FineArts />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Heritage Club</Accordion.Title>
                            <Accordion.Content>
                                <HeritageClub />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Youth Nature Club</Accordion.Title>
                            <Accordion.Content>
                                <Ync />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>English Dramatics</Accordion.Title>
                            <Accordion.Content>
                                <EnglishDramatics />
                            </Accordion.Content>
                        </Accordion.Panel>
                        <Accordion.Panel>
                            <Accordion.Title>Tamil Dramatics</Accordion.Title>
                            <Accordion.Content>
                                <TamilDramatics />
                            </Accordion.Content>
                        </Accordion.Panel>
                        </Accordion>
                    </div>
               </div>
            </div>
        </>
    )
}