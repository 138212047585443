
"use client";

import { Button, Timeline, Carousel } from "flowbite-react";
import { HiArrowNarrowRight, HiCalendar } from "react-icons/hi";
import { useEffect, useState } from "react";
import stu from "../assets/carousal1.jpeg";
import stu1 from "../assets/carousal2.jpeg";
import stu2 from "../assets/carousal3.jpeg";
import stu4 from "../assets/carousal4.jpg";
import store from "../firebase";
import { collection, getDocs, query, where } from "firebase/firestore";
const eventUpdates = collection(store, 'updates');

export default function Events() {
    const [event, setEvents] = useState([]);

    // useEffect(() => {
    //     const queue = query(eventUpdates, where('status', '==', 'true'));
    //     const unsub = onSnapshot(queue, (querySnapshot) => {
    //         const items = [];
    //         querySnapshot.forEach((values) => {
    //             items.push(values.data())
    //         })
    //         items.sort(function (a, b) { return b.date - a.date });
    //         setEvents(items.slice(0, 3));
    //     });
    //     return () => {
    //         unsub();
    //     }
    // }, [])
    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(eventUpdates);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort(function (a, b) { return a.date - b.date });
                setEvents(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])
    
    return (
        <div className="flex flex-row justify-evenly mt-20 mb-20 timeline_mobile">
            <div className="h-[100vh] w-[80%] relative mobile_timeline_carousel">
                <Carousel>
                    <img src={stu} alt="..." className="h-full w-full object-cover" />
                    <img src={stu1} alt="..." className="h-full w-full object-cover" />
                    <img src={stu2} alt="..." className="h-full w-full object-cover" />
                    <img src={stu4} alt="..." className="h-full w-full object-cover" />
                </Carousel>
                <div className="layer">
                    <div className="flex items-center justify-center">
                    <div className="mobile-inner-timeline h-[90%] w-[60%] bg-[#0e7490] opacity-80 p-4 my-8 rounded-lg overflow-y-auto mx-auto flex flex-col items-center">
                        <h3 className="mobile-timeline-header text-[5vmin] text-white text-center mb-6 exo-2-bold tracking-wider">Upcoming Events</h3>
                        <Timeline className="mx-7">
                            {event.map((eventItem, eventKey) => <Timeline.Item key={eventKey}>
                                <Timeline.Point icon={HiCalendar} />
                                <Timeline.Content>
                                    <Timeline.Time className="mobile-timeline-date montserrat text-[#f5f5f5] text-[2vmin]">{eventItem.date.toDate().toDateString()}</Timeline.Time>
                                    <Timeline.Title className="mobile-timeline-title text-white tracking-wide text-[3vmin] exo-2-bold">{eventItem.name.toUpperCase()}</Timeline.Title>
                                    <Timeline.Body className="mobile-timeline-body text-[#EBEAE7] text-[2vmin] w-6/7 overflow-x-hidden quicksand font-semibold">
                                        {eventItem.description.substring(0, 200)}
                                    </Timeline.Body>
                                </Timeline.Content>
                            </Timeline.Item>)}
                        </Timeline>
                        <Button color="light" className="focus:ring-transparent btn transition ease-in-out delay-150 ml-11 -mt-5 text-white montserrat bg-[#EF6F56] hover:text-[#EF6F56] hover:scale-110 duration-200">
                            <a href="/events">Learn More</a>
                            <HiArrowNarrowRight className="ml-2 mt-1 h-3 w-3 hide_tabs" />
                        </Button>
                    </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
