import PhotoGrid from "../photoGrid";
import jrc1 from '../../assets/clubs skl/jrc-1.jpg';
import jrc2 from '../../assets/clubs skl/jrc-2.jpg';
import jrc3 from '../../assets/clubs skl/jrc-3.jpg';
import jrc4 from '../../assets/clubs skl/jrc-01.jpeg';
import jrc5 from '../../assets/clubs skl/jrc-5.png';
import jrc6 from '../../assets/clubs skl/jrc-02.jpeg';

export default function Jrc(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Junior Red Cross (JRC)</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mrs. Angelin Sopiah J</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Humanity:</span> Encouraging students to show compassion and care for others, embodying the principles of the Red Cross.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Service:</span> Instilling a commitment to helping those in need, both within the school and the broader community.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Health and Safety:</span> Educating students on the importance of health, safety, and first aid, empowering them to act in emergencies.</li>
                </ul>
            </div>
        </div>
        <div>
        <PhotoGrid photos={[jrc1,jrc2,jrc3,jrc4,jrc5,jrc6]} />
        </div>
        </>
    )
}