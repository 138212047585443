"use client";

import { Card, Carousel } from "flowbite-react";
import canteen from "../assets/short-gallery-5.jpeg";
import bus1 from "../assets/facilities skl/faci-bus-3.jpg";
import bus2 from "../assets/facilities skl/faci-bus-2.jpg";
import bus3 from "../assets/facilities skl/faci-bus-1.jpg";
import PageHeader from "../components/pageHeader";
import chem1 from "../assets/facilities skl/faci-chem-lab-1.png";
import chem2 from "../assets/facilities skl/faci-chem-lab-2.png";
import chem3 from "../assets/facilities skl/faci-chem-lab-5.png";
import chem4 from "../assets/facilities skl/faci-chem-lab-8.png";
import chem5 from "../assets/facilities skl/faci-chem-lab-9.jpg";
import phy1 from "../assets/facilities skl/faci-phy-lab-1.png";
import bio1 from "../assets/facilities skl/faci-bio-lab-1.png";
import bio2 from "../assets/facilities skl/faci-bio-lab-4.png";
import bio3 from "../assets/facilities skl/faci-bio-lab-5.png";
import bio4 from "../assets/facilities skl/faci-bio-lab-6.jpg";
import library1 from "../assets/facilities skl/faci-library-1.jpg";
import library2 from "../assets/facilities skl/faci-library-2.jpg";
import library3 from "../assets/facilities skl/faci-library-3.jpg";
import library4 from "../assets/facilities skl/faci-library-4.jpg";
import indoor1 from "../assets/facilities skl/faci-indoor-1.png";
import indoor2 from "../assets/facilities skl/faci-indoor-2.png";
import indoor3 from "../assets/facilities skl/faci-indoor-3.png";
import indoor4 from "../assets/facilities skl/faci-indoor-4.png";
import smart1 from "../assets/facilities skl/faci-smart-1.jpg";
import smart2 from "../assets/facilities skl/faci-smart-2.jpg";
import smart3 from "../assets/facilities skl/faci-smart-3.jpg";
import smart4 from "../assets/facilities skl/faci-smart-4.jpg";
import computer1 from "../assets/facilities skl/faci-cs-lab-1.png";
import computer2 from "../assets/facilities skl/faci-cs-lab-2.png";
import computer3 from "../assets/facilities skl/faci-cs-lab-3.jpg";
import computer4 from "../assets/facilities skl/faci-cs-lab-4.jpg";
import playarea1 from "../assets/facilities skl/faci-play-area.jpg";

export default function Facilities() {
    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Facilities"} />
            </div>
            <div className="px-4 md:px-8 lg:px-16 mt-8 mb-8">
                <div className="container mx-auto">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={bus2} alt="Bus Image 1" className="object-cover w-full h-full" />
                                    <img src={bus1} alt="Bus Image 2" className="object-cover w-full h-full" />
                                    <img src={bus3} alt="Bus Image 3" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Bus Transportation</h5>
                                <p className="text-gray-700">
                                    Our bus service ensures safe, reliable transportation with modern buses, offering students a comfortable commute to and from school.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={5000}>
                                    <img src={canteen} alt="Canteen Image 1" className="object-cover w-full h-full" />
                                    <img src={canteen} alt="Canteen Image 2" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Canteen</h5>
                                <p className="text-gray-700">
                                    Our canteen serves nutritious meals daily, catering to various dietary needs and promoting healthy eating habits among students.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={chem1} alt="Chemistry Lab Image 1" className="object-cover w-full h-full" />
                                    <img src={chem2} alt="Chemistry Lab Image 2" className="object-cover w-full h-full" />
                                    <img src={chem3} alt="Chemistry Lab Image 3" className="object-cover w-full h-full" />
                                    <img src={chem4} alt="Chemistry Lab Image 4" className="object-cover w-full h-full" />
                                    <img src={chem5} alt="Chemistry Lab Image 5" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Chemistry Lab</h5>
                                <p className="text-gray-700">
                                    Our chemistry lab is fully equipped with advanced tools, providing students the opportunity to engage in hands-on experiments and explore chemical concepts.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={phy1} alt="Physics Lab Image 1" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Physics Laboratory</h5>
                                <p className="text-gray-700">
                                    Our physics lab features modern equipment, allowing students to conduct experiments and gain practical insights into the principles of physics.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={bio1} alt="Biology Lab Image 1" className="object-cover w-full h-full" />
                                    <img src={bio2} alt="Biology Lab Image 2" className="object-cover w-full h-full" />
                                    <img src={bio3} alt="Biology Lab Image 3" className="object-cover w-full h-full" />
                                    <img src={bio4} alt="Biology Lab Image 4" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Biology Laboratory</h5>
                                <p className="text-gray-700">
                                    Our biology lab is equipped with modern tools and specimens, enabling students to conduct hands-on experiments and deepen their understanding of biological concepts.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={computer1} alt="Computer Lab Image 1" className="object-cover w-full h-full" />
                                    <img src={computer2} alt="Computer Lab Image 2" className="object-cover w-full h-full" />
                                    <img src={computer3} alt="Computer Lab Image 3" className="object-cover w-full h-full" />
                                    <img src={computer4} alt="Computer Lab Image 4" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Computer Lab</h5>
                                <p className="text-gray-700">
                                    Our computer lab is equipped with the latest technology and software, providing students with the tools to enhance their digital skills and engage in interactive learning.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={library1} alt="Library Image 1" className="object-cover w-full h-full" />
                                    <img src={library2} alt="Library Image 2" className="object-cover w-full h-full" />
                                    <img src={library3} alt="Library Image 3" className="object-cover w-full h-full" />
                                    <img src={library4} alt="Library Image 4" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Library</h5>
                                <p className="text-gray-700">
                                    Our library boasts a diverse collection of books, magazines, and digital resources, offering a conducive environment for study and research.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={indoor1} alt="Indoor Sports Image 1" className="object-cover w-full h-full" />
                                    <img src={indoor2} alt="Indoor Sports Image 2" className="object-cover w-full h-full" />
                                    <img src={indoor3} alt="Indoor Sports Image 3" className="object-cover w-full h-full" />
                                    <img src={indoor4} alt="Indoor Sports Image 4" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Indoor Sports Area</h5>
                                <p className="text-gray-700">
                                    Our indoor sports area is designed to host various sports activities, promoting physical fitness and teamwork among students.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={smart1} alt="Smart Rooms Image 1" className="object-cover w-full h-full" />
                                    <img src={smart2} alt="Smart Rooms Image 2" className="object-cover w-full h-full" />
                                    <img src={smart3} alt="Smart Rooms Image 3" className="object-cover w-full h-full" />
                                    <img src={smart4} alt="Smart Rooms Image 4" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Smart Rooms</h5>
                                <p className="text-gray-700">
                                    Our smart rooms are equipped with advanced technology, providing an interactive and modern learning environment.
                                </p>
                            </div>
                        </Card>

                        <Card className="w-full max-w-sm mx-auto bg-white shadow-lg rounded-lg overflow-hidden transform transition-transform hover:scale-105 duration-300">
                            <div className="h-64 overflow-hidden">
                                <Carousel slideInterval={2000}>
                                    <img src={playarea1} alt="Play Area Image" className="object-cover w-full h-full" />
                                </Carousel>
                            </div>
                            <div className="p-4">
                                <h5 className="text-2xl font-semibold text-teal-700 mb-2">Play Area</h5>
                                <p className="text-gray-700">
                                    Our play area is designed for young children to engage in physical activities and enjoy outdoor playtime, enhancing their overall development.
                                </p>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
