import PhotoGrid from "../photoGrid"
import nss1 from '../../assets/clubs skl/nss-01.jpeg'
import nss2 from '../../assets/clubs skl/nss-02.jpeg'
import nss3 from '../../assets/clubs skl/nss-03.jpeg'
import nss4 from '../../assets/clubs skl/nss-04.jpeg'
import nss5 from '../../assets/clubs skl/nss-05.jpeg'
import nss6 from '../../assets/clubs skl/nss-06.jpeg'

export default function Nss(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">National Service Scheme (NSS)</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mr. Pradeep Celestine Charles H</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin]  mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Social Responsibility:</span> Instilling a sense of duty towards society, encouraging students to contribute positively to their communities through service.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Empathy:</span> Developing compassion and understanding for the needs of others, fostering a spirit of kindness and support.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Active Citizenship:</span> Empowering students to take initiative in social causes, becoming active and engaged members of society.</li>
                </ul>
            </div>
        </div>
        <div>
                <PhotoGrid photos={[nss1,nss2,nss3,nss4,nss5,nss6]}/>
            </div>
        </>
    )
}