import React from 'react';
import PhotoGrid from '../photoGrid';
import cubs1 from '../../assets/clubs skl/cubs-1.jpg';
import cubs2 from '../../assets/clubs skl/cubs-2.jpg';
import cubs3 from '../../assets/clubs skl/cubs-3.jpg';
import cubs4 from '../../assets/clubs skl/cubs-4.jpg';

export default function CubsBulbuls() {
    return (
        <>
            <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
                <div>
                    <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Cubs and Bulbuls</h1>
                    <p className="text-[3vmin] mt-4 dark-bluish montserrat">
                        Club Co-ordinator: <span className="soft-red">Mrs. Rebecca Maria Sam</span>
                    </p>
                </div>
                <div>
                    <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                    <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                        <li className="dark-bluish quicksand">
                            <span className="soft-red montserrat-bold">Service:</span> Encouraging students to be helpful and kind to others, both within the school community and beyond.
                        </li>
                        <li className="dark-bluish quicksand">
                            <span className="soft-red montserrat-bold">Teamwork:</span> Teaching the importance of working together, understanding different perspectives, and achieving common goals.
                        </li>
                        <li className="dark-bluish quicksand">
                            <span className="soft-red montserrat-bold">Leadership:</span> Nurturing leadership qualities, helping students take initiative, and guiding others responsibly.
                        </li>
                    </ul>
                </div>
            </div>
            <div>
                <PhotoGrid photos={[cubs1,cubs3,cubs4]} />
            </div>
        </>
    );
}
