import schoolValuesVideo from "../assets/values-video.mp4"

export default function SchoolValues(){
    return(
        <>
        <div className="flex flex-col items-center gap-4">
            <div>
                <video className="h-[400px] mobile_video_height shadown-2xl my-8" src={schoolValuesVideo} autoPlay loop={true} muted alt="not found"/>
            </div>     
        </div>
        </>
    )
}