import PhotoGrid from "../photoGrid"
import choir1 from "../../assets/clubs skl/choir-1.jpg"

export default function Choir(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Choir</h1>
                <p className="text-lg mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mrs. Gladys Thabitha</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Harmony:</span> Teaching students the importance of working together in unison, creating beautiful music through collaboration.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Musicality:</span> Developing students' musical skills and understanding, fostering a deep appreciation for the art of singing.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Discipline:</span> Encouraging consistent practice and dedication to improve vocal performance and teamwork within the choir.</li>
                </ul>
            </div>
        </div>
        <div>
            <PhotoGrid photos={[choir1]} />
        </div>
        </>
    )
}