import schoolHouses from "../assets/houses-video.mp4"

export default function SchoolHouses(){
    return(
        <>
        <div className="flex flex-col items-center gap-8">
            
            <div>
                <video className="h-[400px] mobile_video_height shadow-2xl my-8" autoPlay="autoplay" loop="true" src={schoolHouses} muted alt="not found"/>
            </div>     
        </div>
        </>
    )
}