import Counter from "./counter";

export default function SchoolNumbers(){
    return(
        <div className="w-full h-[20vmin] bg-[#f5f5f5] flex flex-col items-center justify-center mobile-counter pt-4">
            <div className="numbers text-xl w-full">
                <Counter start={500} end={1000} title="Student Prodigies" />
                <Counter start={0} end={60} title="Certified Teachers & Staff" />
                <Counter start={10} end={186} title="Years of Legacy" />
            </div>
        </div>
        
    );
}