import PageHeader from "../components/pageHeader";
import Graduate from "../assets/collage-2-try.png";
import ExtraCurricular from "../components/extraCurricular";
import { useState } from "react";
import StudentProdigies from "../components/studentProdigies";
import Curriculum from "../components/curriculum";

export default function Academics() {
    const [selectedContent, setSelectedContent] = useState('curriculum');

    const content = {
        curriculum: <Curriculum />,
        studentProdigies: <StudentProdigies />,
        extraCurricular: <ExtraCurricular />,
    };

    return(
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Educational Journey"} />
            </div>
            <div className="mb-10">
               <div className="flex flex-col gap-10">
                    <div className="image-container overflow-hidden w-full h-auto">
                        <img src={Graduate} alt="image not loaded" className="w-full h-full object-contain" style={{ objectPosition: 'center 80%' }}/>
                    </div>
                        <div className="left-side">
                            <div className="list-bar">
                            <ul className="flex flex-row justify-center gap-x-2 montserrat text-[3vmin] dark-bluish custom_active">
                                <li className={`border-b  p-4 cursor-pointer transition-colors text-center duration-200 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'curriculum' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('curriculum')}>School Curriculum</li>
                                <li className={`border-b  p-4 cursor-pointer transition-colors text-center duration-200 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'extraCurricular' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('extraCurricular')}>Extra Curricular</li>
                                <li className={`border-b  p-4 cursor-pointer transition-colors text-center duration-200 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'studentProdigies' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('studentProdigies')}>Student Prodigies</li>
                            </ul>
                            </div>
                        </div>
                            <div className="w-[80%] mobile-academics mx-auto">
                                {content[selectedContent]}
                            </div>
               </div>
            </div>
        </> 
    )
}