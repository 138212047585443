"use client";

import { Textarea, Label, TextInput, Card, Select } from "flowbite-react";
import MapBox from "../../components/mapBox";
import Phone from "../../assets/phone.png";
import Address from "../../assets/address.png";
import PageHeader from "../../components/pageHeader";
import Email from "../../assets/email.png";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './contact.css';
import Swal from 'sweetalert2';
import { FaPhoneVolume } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { MdLocationPin } from "react-icons/md";

export default function Contact() {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        Swal.fire({
            title: 'Sending...',
            text: 'Please wait while your enquiry is being sent.',
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();  // Display the loading spinner
            }
          });
   

        emailjs
            .sendForm('service_71lifcy', 'template_waux1jv', form.current, {
                publicKey: 'Aue7w_OvC9oVBAVDu',
            })
            .then(
                () => {
                    Swal.fire({
                        title: 'Email Sent',
                        text: 'Your Enquiry is sent. We will reach out to you soon.',
                        icon: 'success',
                        confirmButtonText: 'Cool'
                      });
                    form.current.reset();
                },
                (error) => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Error sending email, please try again',
                        icon: 'error',
                        confirmButtonText: 'Try Again'
                      });
                    console.log('FAILED...', error.text);
                    form.current.reset();
                },
            );
    };

    return (
        <div className="h-full">
            <div className="soft-red-bg px-4">
                <PageHeader title={"We're Here For You"} />
            </div>
            <div className="contact_main">
                <div className="flex flex-col items-center mx-32 p-11 mobile-contact-details">
                    <p className="text-[3vmin] text-center quicksand dark-bluish">Whether you have questions, feedback, or need assistance, feel free to reach out to us using the form below or through our contact details. Our team is ready to assist you and ensure you get the support you need.</p>
                </div>
                <div className="flex justify-center w-[40%] mr-auto ml-auto shadow-2xl contact-form">
                    <form ref={form} onSubmit={sendEmail} className="flex flex-col w-[80%] gap-4 p-9">
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="name" className="montserrat-bold teal" value="Your Name:" />
                            </div>
                            <TextInput id="name" type="text" name="user_name" className="quicksand" placeholder="Enter Your Name" required shadow />
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="email2" className="montserrat-bold teal" value="Your Email Id" />
                            </div>
                            <TextInput id="email2" type="email" name="user_email" className="quicksand" placeholder="Enter Your Email Id:" required shadow />
                        </div>
                        <div className="max-w-md">
                            <div className="mb-2 block">
                                <Label htmlFor="query_type" className="montserrat-bold teal" value="Select your enquiry type:" />
                            </div>
                            <Select id="query_type" className="quicksand" name="user_enquiry" required>
                                <option>General Enquiry</option>
                                <option>Admissions</option>
                                <option>Sponsor a student</option>
                            </Select>
                        </div>
                        <div>
                            <div className="mb-2 block">
                                <Label htmlFor="message" className="montserrat-bold teal" value="Your Message:" />
                            </div>
                            <Textarea id="message" name="message" className="quicksand" placeholder="Enter Your Message Here..." required rows={4} />
                        </div>
                        <div className="w-[100%] flex justify-center">
                            <input type="submit" value="Submit" className="w-52 rounded-md h-12 cursor-pointer text-white montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state mx-auto" />
                        </div>
                    </form>
                </div>
            </div>
            <div className="flex flex-row justify-evenly items-center mt-20 mb-20 contact-map-and-card">
                <div className="contact-map shadow-2xl">
                    <MapBox />
                </div>
                <div className="flex flex-col justify-center items-center w-[50vw] h-[50vh] contact-card">
                    <Card className="w-[80%] shadow-2xl">
                        <div className="flow-root">
                            <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="shrink-0 p-1">
                                        <MdLocationPin className="text-[2rem] soft-red" />
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm teal montserrat-bold font-medium text-gray-900 dark:text-white">1, Broadway Rd, Bundar Rama Garden, George Town</p>
                                            <p className="truncate text-sm montserrat teal">Chennai, Tamil Nadu 600001</p>
                                        </div>
                                    </div>
                                </li>
                                <li className="py-3 sm:py-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="shrink-0 p-1">
                                        <IoIosMail className="text-[2rem] soft-red" />
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm montserrat-bold teal">contact@bishopcorrieangloindianhss.org.in</p>
                                            <p className="truncate text-sm text-gray-500 dark:text-gray-400"></p>
                                        </div>
                                    </div>
                                </li>
                                <li className="pb-0 pt-3 sm:pt-4">
                                    <div className="flex items-center space-x-4">
                                        <div className="shrink-0 p-1">
                                            {/* <img
                                                alt="phone"
                                                height="32"
                                                src={Phone}
                                                width="32"
                                                className="rounded"
                                            /> */}
                                            <FaPhoneVolume className="text-[1.5rem] ml-1 soft-red" />
                                        </div>
                                        <div className="min-w-0 flex-1">
                                            <p className="truncate text-sm montserrat-bold teal tracking-wide">+91 91761 55238</p>
                                            <p className="truncate text-sm text-gray-500 dark:text-gray-400"></p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </Card>
                </div>
            </div>
        </div>

    )
}