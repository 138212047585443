
'use client';

import { MegaMenu, Navbar } from 'flowbite-react';
import { useState } from 'react';
import { HiArrowRight, HiChevronDown, HiChevronUp } from 'react-icons/hi';
import princi from '../assets/princi-thumb.jpeg'
import corres from '../assets/corres-thumb.jpeg'
import logo from '../assets/logo-soft-red.png'
import logoThumbnail from '../assets/new_logo.png'

export default function SideBar() {
    const [isAboutDropdownOpen, setIsAboutDropdownOpen] = useState(false);
    const [chevronUp, setChevronup] = useState(false);

    const toggleAboutDropdown = () => {
        setIsAboutDropdownOpen(!isAboutDropdownOpen);
        setChevronup(!chevronUp);
    };
    const toggleAboutDropdownClick = () => {
        setIsAboutDropdownOpen(!isAboutDropdownOpen);
        setChevronup(!chevronUp);

    }
    const setAboutOpen = () => {
        setIsAboutDropdownOpen(true);
    }
    const setBothFalse = () => {
        setIsAboutDropdownOpen(false);
    }
    // const setMobileAboutClose = () => {
    //     setIsMobileAboutDropdownOpen(false);
    // }
    // const setBothFalse = () => {
    //     setIsAboutDropdownOpen(false);
    //     setIsMobileAboutDropdownOpen(false);
    // }
    const handleAboutEvent = () => {
        setAboutOpen();
        setChevronup(true);
    }

  
    return (
        <MegaMenu className='sticky top-0 z-50 bg-white py-4'>
            <Navbar.Brand href="/">
                {/* <img alt="menu_image" src="/favicon.svg" className="mr-3 h-6 sm:h-9" /> */}
                <span className="self-center whitespace-nowrap text-xl font-semibold text-[#EF6F56]">
                    <img src={logo} alt="logo not loaded" className='h-12 w-42' />
                </span>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Navbar.Link href="/" onMouseEnter={setBothFalse} className='text-[#EF6F56] font-bold nav-text tracking-widest montserrat-bold transform transition-all ease-in-out duration-200 hover:scale-110'>HOME</Navbar.Link>
                <Navbar.Link href="/admission" onMouseEnter={setBothFalse} className='text-[#EF6F56] font-bold nav-text tracking-widest montserrat-bold  transform transition-all ease-in-out duration-200 hover:scale-110'>ADMISSIONS</Navbar.Link>
                <MegaMenu.DropdownToggle className='text-[#EF6F56] font-bold nav-text montserrat-bold tracking-widest transform transition-transform duration-200 ' onMouseEnter={handleAboutEvent} onClick={toggleAboutDropdownClick}>
                    ABOUT
                    { !chevronUp && <HiChevronDown className="ml-2"/> }
                    { chevronUp && <HiChevronUp className="ml-2" /> }
                </MegaMenu.DropdownToggle>
                <Navbar.Link href="/academics" onMouseEnter={setBothFalse} className='text-[#EF6F56] font-bold nav-text tracking-widest montserrat-bold  transform transition-all ease-in-out duration-200 hover:scale-110'>ACADEMICS</Navbar.Link>
                <Navbar.Link href="/contact" onMouseEnter={setBothFalse} className='text-[#EF6F56] font-bold nav-text tracking-widest montserrat-bold  transform transition-all ease-in-out duration-200 hover:scale-110'>CONTACT</Navbar.Link>
                <Navbar.Link href="/donate" onMouseEnter={setBothFalse} className='text-[#EF6F56] font-bold nav-text tracking-widest montserrat-bold  transform transition-all ease-in-out duration-200 hover:scale-110'>SPONSOR</Navbar.Link>
            </Navbar.Collapse>
            {isAboutDropdownOpen && (
                <MegaMenu.Dropdown className='w-full h-full normal_sideBar' onMouseEnter={setAboutOpen} onMouseLeave={toggleAboutDropdown}>
                    <div className="mt-6 border-y border-[#EF6F56] bg-white shadow-sm" >
                        <div className="grid py-9 mx-2 md:grid-cols-4 grid-flow-col">
                            <div className='pr-9 py-6 mr-9 border-r-2 border-[#EF6F56]'>
                                <div className='w-full h-36 flex shadow-2xl nav-img transition-transform duration-300 hover:scale-110'>
                                    <div className='w-[40%] overflow-hidden flex flex-col items-center justify-center border-none shadow-2xl'>
                                        <img src={logoThumbnail} className='w-full h-full object-contain object-center' alt='logo_thumbnail' />
                                    </div>
                                    <div className='flex flex-col justify-center items-center py-6'>
                                        <h5 className="text-lg text-center montserrat-bold text-[#0e7490]">
                                            SCHOOL
                                        </h5>
                                        <p className="font-normal text-[#37474f] text-center hyphens-auto indent-0 text-xs pb-2 tracking-wide quicksand-bold">
                                            Discover more about our school.</p>
                                        <button
                                            type="button"
                                            className="text-white self-center w-24 montserrat bg-[#EF6F56] focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded text-xs px-3 py-1.5 text-center transition-transform duration-300 hover:scale-110">
                                            <a href='/about'>Show More</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='pr-9 py-6 mr-9 border-r-2 border-[#EF6F56]'>
                                <div className='w-full h-36 flex shadow-2xl nav-img transition-transform duration-300 hover:scale-110'>
                                    <div className='w-[50%] overflow-hidden flex items-center justify-center border-none shadow-2xl'>
                                        <img src={corres} className='w-full h-full object-cover object-center' alt='corres_thumbnail' />
                                    </div>
                                    <div className='flex flex-col justify-center items-center py-6'>
                                        <h5 className="text-lg text-center montserrat-bold text-[#0e7490]">
                                            CORRESPONDENT
                                        </h5>
                                        <p className="font-normal text-[#37474f] text-xs text-center hyphens-auto indent-0 pb-2 tracking-wide quicksand-bold">
                                            Meet Correspondent Mr. Y DANIEL YESUPATHAM</p>
                                        <button
                                            type="button"
                                            className="text-white self-center w-24 montserrat bg-[#EF6F56] focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded text-xs px-3 py-1.5 text-center transition-transform duration-300 hover:scale-110">
                                            <a href='/correspondent'>Show More</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='pr-9 py-6 mr-9 border-r-2 border-[#EF6F56]'>
                                <div className='w-full h-36 flex shadow-2xl nav-img transition-transform duration-300 hover:scale-110'>
                                    <div className='w-[40%] overflow-hidden flex items-center justify-center border-none shadow-2xl'>
                                        <img src={princi} className='w-full h-full object-cover object-center' alt='prinicpal_thumbnail' />
                                    </div>
                                    <div className='flex flex-col justify-center items-center py-6'>
                                        <h5 className="text-lg text-center montserrat-bold text-[#0e7490]">
                                            PRINCIPAL
                                        </h5>
                                        <p className="font-normal text-[#37474f] text-xs text-center hyphens-auto indent-0 pb-2 tracking-wide quicksand-bold">
                                            Meet Principal Mr. A.J.F. Prabakaran</p>
                                        <button
                                            type="button"
                                            className="text-white self-center w-24 montserrat bg-[#EF6F56] focus:outline-none focus:ring-2 focus:ring-blue-300 font-medium rounded text-xs px-3 py-1.5 text-center transition-transform duration-300 hover:scale-110">
                                            <a href='/principal'>Show More</a>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className='w-full h-36 flex justify-center py-6'>
                                <ul className="mb-4 hidden space-y-4 md:mb-0 soft-red uppercase tracking-wider montserrat-bold md:block" aria-labelledby="mega-menu-full-image-button">
                                    <li>
                                        <a href="/events" className="hover:text-[#0e7490] transition-colors">
                                            News & Events
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/staff" className="hover:text-[#0e7490] transition-colors">
                                            Meet Our Staff
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/clubs" className="hover:text-[#0e7490] transition-colors">
                                            Our Clubs
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/facilities" className="hover:text-[#0e7490] transition-colors dark:hover:text-primary-500">
                                            Facilities
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </MegaMenu.Dropdown>
                
            )}
            {isAboutDropdownOpen && (
                <MegaMenu.Dropdown className='w-full h-full mobile_sideBar' onMouseEnter={setAboutOpen} onMouseLeave={toggleAboutDropdown}>
                    <div className="mt-6 border-y border-[#EF6F56] bg-white shadow-sm" >
                        <div className="grid py-9 md:grid-cols-4 grid-flow-col">
                        <div className='w-full h-36 flex flex-col items-center'>
                                <ul className="mb-4 space-y-4 montserrat-bold text-[3.5vmin] tracking-wider uppercase md:mb-0 md:block" aria-labelledby="mega-menu-full-image-button">
                                <li>
                                        <a href="/about" className="teal hover:text-[#ef6f56] transition-colors">
                                            About School
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/correspondent" className="teal hover:text-[#ef6f56] transition-colors">
                                           Correspondent
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/principal" className="teal hover:text-[#ef6f56] transition-colors">
                                            Principal's Desk
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div className='w-full h-36 flex flex-col items-center'>
                                <ul className="mb-4 space-y-4 uppercase text-[3.5vmin] tracking-wider montserrat-bold md:mb-0 md:block" aria-labelledby="mega-menu-full-image-button">
                                    <li>
                                        <a href="/events" className="teal hover:text-[#ef6f56] transition-colors">
                                            News & Events
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/staff" className="teal hover:text-[#ef6f56] transition-colors">
                                            Meet Our Staff
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/clubs" className="teal hover:text-[#ef6f56] transition-colors">
                                            Our Clubs
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/facilities" className="teal hover:text-[#ef6f56] transition-colors">
                                            Facilities
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </MegaMenu.Dropdown>
                
            )}
            {/* {isAcademicsDropdownOpen && (
                <MegaMenu.Dropdown className='w-full h-full' onMouseEnter={setAcademicsOpen} onMouseLeave={toggleAcademicsDropdown}>
                    <div className="mt-6 border-y border-gray-200 bg-white shadow-sm dark:border-gray-600 dark:bg-gray-800" >
                        <div className="mx-auto grid max-w-screen-xl px-4 py-5 text-sm text-gray-500 dark:text-gray-400 md:grid-cols-3 md:px-6">
                            <ul className="mb-4 hidden space-y-4 md:mb-0 md:block" aria-labelledby="mega-menu-full-image-button">
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                       Alumni Connect
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Student Prodigies
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Curriculum
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Online Stores
                                    </a>
                                </li>
                            </ul>
                            <ul className="mb-4 space-y-4 md:mb-0">
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Our Blog
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        License
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="hover:text-primary-600 dark:hover:text-primary-500">
                                        Resources
                                    </a>
                                </li>
                            </ul> */}
                            {/* <a href="#" className="rounded-lg bg-gray-500 p-8 text-left">
                                <p className="mb-5 max-w-xl font-extrabold leading-tight tracking-tight text-white">
                                    Preview the new Flowbite dashboard navigation.
                                </p>
                                <button
                                    type="button"
                                    className="inline-flex items-center rounded-lg border border-white px-2.5 py-1.5 text-center text-xs font-medium text-white hover:bg-white hover:text-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-700"
                                >
                                    Get started
                                    <HiArrowRight className="ml-2" />
                                </button>
                            </a> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </MegaMenu.Dropdown> */}
            {/* )} */}
        </MegaMenu>
    );
}
