import schoolPrayer from "../assets/prayer-video.mp4"

export default function SchoolPrayer(){
    return(
        <>
        <div className="flex flex-col items-center gap-8">
            <div>
                <video className="h-[400px] mobile_video_height shadow-2xl my-8" src={schoolPrayer} autoPlay loop={true} muted alt="not found"/>
            </div>     
        </div>
        </>
    )
}