import React, { useState, useEffect, useRef } from 'react';

const PhotoGrid = ({ photos }) => {
    const [loadedPhotos, setLoadedPhotos] = useState([]);
    const gridRef = useRef(null);

    useEffect(() => {
        const handleIntersection = (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    photos.forEach((photo, index) => {
                        setTimeout(() => {
                            setLoadedPhotos((prev) => {
                                if (!prev.includes(photo)) {
                                    return [...prev, photo];
                                }
                                return prev;
                            });
                        }, index * 300);
                    });
                    observer.unobserve(entry.target);
                }
            });
        };

        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            rootMargin: '0px',
            threshold: 0.8,
        });

        if (gridRef.current) {
            observer.observe(gridRef.current);
        }

        return () => {
            if (gridRef.current) {
                observer.unobserve(gridRef.current);
            }
        };
    }, [photos]);

    return (
        <div className="photo-grid" ref={gridRef}>
            {loadedPhotos.map((photo, index) => (
                <div key={index} className="photo shadow-2xl">
                    <img src={photo} alt={`Photo ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};

export default PhotoGrid;
