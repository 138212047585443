import schoolMissionVision from "../assets/mission-video.mp4"

export default function SchoolMission(){
    return(
        <>
        <div className="flex flex-col items-center gap-8">
            <div>
                    <video className="h-[400px] mobile_video_height my-8 shadow-2xl" src={schoolMissionVision} autoPlay loop={true} muted alt="not found"/>
                </div>
        </div>
        </>
    )
}