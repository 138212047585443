

export default function Rsp(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Road Safety Patrol (RSP)</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mr. Rajini Jebakumar J</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] teal mb-4 exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Awareness:</span> Educating students on the importance of road safety, ensuring they understand the rules and practices that protect themselves and others.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Responsibility:</span> Encouraging students to take responsibility for their actions on the road and to lead by example in promoting safe behavior.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Vigilance:</span> Training students to be observant and proactive in identifying potential dangers, helping to prevent accidents and promote safety.</li>
                </ul>
            </div>
        </div>
        <div>
                {/* <ShortGallery /> */}
            </div>
        </>
    )
}