"use client";

import { Footer } from "flowbite-react";
import { BsFacebook,  } from "react-icons/bs";
import BishopCorrie from '../assets/footer_logo.png';
import { FaMapMarkerAlt } from "react-icons/fa";

export default function PageFooter() {
  return (
    <Footer className="bg-gradient-to-r from-[#37474f] to-[#0e7490] rounded-none text-white py-8" container>
      <div className="container mx-auto px-4">
        <div className="flex flex-col sm:flex-row sm:justify-between">
          <div className="flex items-center mb-6 sm:mb-0">
            <Footer.Brand
              className="h-fit w-fit"
              href="#"
              src={BishopCorrie}
              alt="Logo"
              name=""
            />
          </div>
          <div className="grid grid-cols-2 gap-8 sm:grid-cols-2 sm:gap-12 quicksand">
            <div>
              <Footer.Title className='text-lg font-semibold text-[#f5f5f5]' title="about" />
              <Footer.LinkGroup className='text-sm text-[#f5f5f5]'>
                <Footer.Link href="/about" className="hover:text-[#EF6F56] transition-colors">School</Footer.Link>
                <Footer.Link href="/admission" className="hover:text-[#EF6F56] transition-colors">Admission</Footer.Link>
                <Footer.Link href="/academics" className="hover:text-[#EF6F56] transition-colors">Academics</Footer.Link>
                <Footer.Link href="/contact" className="hover:text-[#EF6F56] transition-colors">Contact Us</Footer.Link>
              </Footer.LinkGroup>
            </div>
            <div>
              <Footer.Title className='text-lg font-semibold text-[#f5f5f5]' title="Opening Hours" />
              <Footer.LinkGroup className='text-sm'>
                <h6 className="text-[#f5f5f5]">8:30am - 3:30pm</h6>
              </Footer.LinkGroup>
            </div>
          </div>
        </div>
        <Footer.Divider className="my-6 border-gray-700" />
        <div className="flex flex-col sm:flex-row sm:justify-between items-center">
          <Footer.Copyright className='text-sm text-[#f5f5f5]' href="#" by="All Rights Reserved." year={2024} />
          <div className="mt-4 flex space-x-6 sm:mt-0">
            <Footer.Icon className='text-2xl hover:text-[#EF6F56] transition-transform transform hover:scale-125' target="_blank" href="https://www.facebook.com/bishopcorrieheaven" icon={BsFacebook} />
            <Footer.Icon className='text-2xl hover:text-[#EF6F56] transition-transform transform hover:scale-125'target="_blank"  href="https://www.google.com/maps/dir//1,+Broadway+Rd,+Bundar+Rama+Garden,+George+Town,+Chennai,+Tamil+Nadu+600001/@13.1026017,80.2038755,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a526f5ce22b8615:0x8c43f99ae9f898b6!2m2!1d80.2862774!2d13.1026148?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D" icon={FaMapMarkerAlt} />
          </div>
        </div>
        <div className="mt-6 text-center text-sm">
          <p>Website designed & developed by <a href="mailto:expectowebservices@gmail.com" className="text-[#EF6F56] hover:underline">Expecto Web Services</a></p>
        </div>
      </div>
    </Footer>
  );
}
