import Warning from '../assets/warning.png';

export default function NotFoundPage() {
    return(
        <div className="w-[90%] mx-auto my-10">
            <div className="w-[80%] mx-auto flex flex-col items-center justify-center text-center my-10 py-6 shadow-xl">
                <img src={Warning} alt="warning" className='w-20 h-20' />
                <h2 className="text-[6vmin] tracking-wide exo-2-bold soft-red">404 Page not found!</h2>
                <p className="text-[4vmin] quicksand tracking-wide dark-bluish">Please re-check the page URL.</p>
                <button className="focus:ring-transparent rounded-md my-6 p-2 text-[#f5f5f5] w-52 montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state"><a href="/">Go Back Home</a></button>
            </div>
        </div>
    )
}