import { useState, useEffect } from "react";
import { Carousel } from "flowbite-react";
import store from '../firebase'
import { getDocs, query, collection } from "firebase/firestore";
// import userEvent from "@testing-library/user-event";
import "../pages/events/events.css"
const archivedEvents = collection(store, 'archived_events');

export default function ArchivedEvents() {
    const [pics, setPics] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(archivedEvents);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort(function (a, b) { return a.date - b.date });
                setPics(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])
    return (
        <>
            {pics && pics.map((item, index) =>
                
                <div className="flex w-full sm:w-[70%] lg:w-2/5 shadow-xl">
                    <div key={index} className="flex flex-col">

                        <div className="image_container h-56 sm:h-64 xl:h-80 2xl:h-96">
                            <Carousel>
                                {item.imageUrls.map((image, index) => <img src={image} key={index} alt="title" className="h-full" />)}
                            </Carousel>
                        </div>
                        <div className="p-4 flex flex-col justify-evenly h-[50%]">
                        <div className="">
                            <h1 className="mobile-event-title text-[5vmin] exo-2-bold capitalize tracking-wide teal">{item.name}</h1>
                        </div>
                        <div className="">
                            <h1 className="mobile-event-title text-[3vmin] tracking-wide soft-red montserrat">{item.date.toDate().toDateString()}</h1>
                        </div>
                        <div className="text-[3vmin] quicksand">
                            <p className="mobile-event-description dark-bluish">{item.description}</p>
                        </div>
                        </div>
                    </div>
                    
                </div>)}
        </>
    )
}