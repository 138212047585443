import PhotoGrid from "../photoGrid"
import ync1 from '../../assets/clubs skl/ync-01.jpeg'
import ync2 from '../../assets/clubs skl/ync-02.jpeg'
import ync3 from '../../assets/clubs skl/ync-03.jpeg'



export default function Ync(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Youth Nature Club (YNC)</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mr. John Solomon B.J</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Environmental Stewardship:</span> Teaching students to care for the environment and take action to preserve natural resources for future generations.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Respect for Nature:</span> Cultivating a deep appreciation for the beauty and importance of nature, encouraging students to protect and cherish it.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Sustainability:</span> Promoting sustainable practices that reduce harm to the environment and contribute to the well-being of the planet.</li>
                </ul>
            </div>
        </div>
        <div>
                <PhotoGrid photos={[ync1,ync2,ync3]} />
            </div>
        </>
    )
}