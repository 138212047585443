import React from 'react';

const Tooltip = ({ text, position, visible }) => {
    return (
        <div
            className={`tooltip ${visible ? 'visible' : ''}`}
            style={{ left: position.x, top: position.y }}
        >
            {text}
        </div>
    );
};

export default Tooltip;