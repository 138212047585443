import PhotoGrid from "../photoGrid"
import arts1 from '../../assets/clubs skl/arts-01.jpeg';
import arts2 from '../../assets/clubs skl/arts-02.jpeg';
import arts3 from '../../assets/clubs skl/arts-03.jpeg';
import arts4 from '../../assets/clubs skl/arts-04.jpeg';
import arts5 from '../../assets/clubs skl/arts-05.jpeg';
import arts6 from '../../assets/clubs skl/arts-06.jpeg';
import arts7 from '../../assets/clubs skl/arts-07.jpeg';
import arts8 from '../../assets/clubs skl/arts-08.jpeg';
import arts9 from '../../assets/clubs skl/arts-09.jpeg';

export default function FineArts(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Fine Arts Club</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mr. Pushparaj D</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Motto</span> The Literary Club is on a committed mission to amplify all the thought provoking voices of students and to help them realize their ideas.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Cultural Appreciation:</span> Teaching students to value and explore different cultures and artistic traditions, broadening their understanding and appreciation of the arts.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Principle:</span> To encourage students to become orators and display their intellectual, independent thinking skills and imbibe a sense of confidence.</li>
                </ul>
            </div>
        </div>
        <div>
                <PhotoGrid photos={[arts1,arts2,arts3,arts4,arts5,arts6,arts7,arts8,arts9]} />
        </div>
        </>
    )
}