"use client";

import PageHeader from "../../components/pageHeader";
import principal from '../../assets/principal.jpeg';
import principal2 from '../../assets/princi-2-image.jpg';
import ImageWithTooltips from "../../components/studentsImage";
import "./principal.css";

export default function Principal() {
  return (
    <div>
        <div className="soft-red-bg px-4">
                <PageHeader title={"Principal's Desk"} />
            </div>
        <div className="principal-container w-[80%] mx-auto mt-10 flex flex-col gap-16 mb-10">
            <section className="flex mobile-section-1 h-[30vw] w-auto">
                <div className="w-1/2 flex justify-center items-center pr-4 princi-img-container">
                    <div className="rounded-lg overflow-hidden box-shadow shadow-2xl">
                        <img className="w-full h-[400px] object-cover" src={principal} alt="principal" />
                    </div>
                </div>
                <div className="content-container w-1/2 flex flex-col pl-4 items-end justify-around">
                    <div className="w-full h-[25%] text-right flex flex-col justify-evenly">
                        <h1 className="text-2xl tracking-wider teal montserrat-bold">MR. A.J.F. PRABAKARAN</h1>
                        <h6 className="quicksand-bold dark-bluish tracking-wider">M.Sc.,M.Ed.,M.Phil.,</h6>
                    </div>
                    <div className="w-full border-t-4 border-[#EF6F56] princi-content">
                        <p className="mt-4 text-justify quicksand dark-bluish">It is with immense pleasure and a deep sense of gratitude that I address you all through this message from the Principal's Desk. As we stand on the threshold of a new academic session, I extend a hearty and warm welcome to all my students, staff and parents. Each academic year is a new height scaled, another dream realized with new targets set for the future. I would like to share a few highlights and initiatives that we have planned for our school community </p>
                    </div>
                </div>
            </section>
            <section className="h-[300px] flex flex-col justify-between w-auto mobile-section-2">
                <div className="title-container">
                    <h1 className="title text-center text-2xl exo-2-bold tracking-widest soft-red shadow-bottom">OUR MISSION: Shaping Tomorrow Today</h1>
                </div>
                <div className="flex flex-wrap mobile-princi-cards">
                    <div href="#" className="w-full sm:w-1/2 lg:w-1/4 border-r-4 border-[#f5f5f5] shadow-sm">
                        <div className="card-contents m-4 flex flex-col gap-4">
                            <h5 className="text-xl exo-e-bold tracking-wide teal text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Academic Excellence
                            </h5>
                            <p className="text-sm text-justify quicksand dark-bluish">
                            "Our commitment to academic excellence is unwavering. Our dedicated teachers are here to guide each student on their journey."
                            </p>
                        </div>
                    </div>
                    <div href="#" className="w-full sm:w-1/2 lg:w-1/4 border-r-4 border-[#f5f5f5] shadow-sm">
                        <div className="card-contents m-4 flex flex-col gap-4">
                            <h5 className="text-xl exo-e-bold tracking-wide teal text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Holistic Development
                            </h5>
                            <p className="text-sm dark-bluish quicksand text-justify">
                            Education is not limited to textbooks. We encourage our students to participate in extracurricular activities, sports, and community service to foster a well-rounded development. we are conducting Zonal sports in our premises.                            </p>
                        </div>
                    </div>
                    <div href="#" className="w-full sm:w-1/2 lg:w-1/4 border-r-4 border-[#f5f5f5] shadow-sm">
                        <div className="card-contents m-4 flex flex-col gap-4">
                            <h5 className="text-xl exo-e-bold tracking-wide teal text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Digital Transformation
                            </h5>
                            <p className="text-justify text-sm quicksand dark-bluish">
                            Keeping pace with the digital age, we are further enhancing our online presence. Our website and communication platforms will keep you updated on school events, achievements, and important information.                            </p>
                        </div>
                    </div>
                    <div href="#" className="w-full sm:w-1/2 lg:w-1/4 border-r-4 border-[#f5f5f5] shadow-sm">
                        <div className="card-contents m-4 flex flex-col gap-4">
                            <h5 className="text-xl exo-e-bold tracking-wide teal text-center font-bold tracking-tight text-gray-900 dark:text-white">
                            Sponsors
                            </h5>
                            <p className="text-sm quicksand dark-bluish text-justify">
                            "We are helping needy children with their studies. Through scholarships and support, we aim to provide them with better educational opportunities."                      </p>  </div>
                    </div>
                </div>
            </section>
            <section className="flex justify-between mobile-section-3">
              
                <div className="principal-content w-2/3 h-[400px] pr-12 flex flex-col justify-around">
                    <p className="text-justify quicksand dark-bluish">
                    As Principal I am hugely impressed by the commitment of the management and the staff for providing excellent all-round education for our students. Today, we proudly stand as a beacon of quality education in George Town, Chennai.
                    </p>
                    <p className="text-justify quicksand dark-bluish">
                    At CSI BISHOP CORRIE A.I.HR.SEC.SCHOOL, our mission has always been to provide a holistic and value-based education that not only empower students with knowledge but also helps them develop into responsible, compassionate, and socially aware individuals. We believe that education is not just about academic excellence but also about character formation, and we strive to instill in our students the values of integrity, discipline, and empathy.
                    </p>
                    <p className="text-justify quicksand dark-bluish">
                    In conclusion, I am immensely proud of the achievements of our students and the unwavering dedication of our faculty and staff. Together, we will continue to uphold the highest standards of education and nurture the next generation of thinkers, leaders, and change-makers.
                    </p>
                    <p className="text-justify quicksand dark-bluish">
                    Thank you for being a part of the CSI BISHOP CORRIE A.I.HR.SEC.SCHOOL family. Together, let us strive for excellence and make a difference in the lives of our students and our community.
                    </p>
                </div>
                
                <div className="img-container w-1/3 h-[400px] flex flex-col justify-center princi-img-container">
                  <div className="overflow-hidden rounded-lg">
                    <img src={principal2} className="h-[400px] w-[400px] object-cover object-[center]" alt="principal_group" />
                  </div>
                </div>
            </section>
            <section className="flex flex-col items-center justify-center mobile-section-3">
                <div className="mb-10 w-full hide-last-section-mobile title-container">
                    <h1 className="title text-center text-2xl exo-2-bold tracking-widest soft-red shadow-bottom">Through the Eyes of a Student: Appreciating Our Principal</h1>
                </div>
                <div>
                <ImageWithTooltips />
                </div>
            </section>
         </div>
      </div>
  );
}
