import PageHeader from "../components/pageHeader";
import corres from "../assets/corres-on-mic.jpg";
import '../pages/principal/principal.css';

export default function CorresPage() {
    return (
        <div>
            <div className="soft-red-bg px-4">
                <PageHeader title={"School Correspondent"} />
            </div>
            <div className="w-[60%] corres-mobile mx-auto mt-10 mb-10">
                <div className="container flex flex-col justify-center items-center">
                    <div className="image mb-10">
                        <div className="corres-img-container w-80 h-80 rounded-full shadow-lg overflow-hidden">
                            <img src={corres} className="w-full h-full object-cover object-[top]" alt="image not loaded" />
                        </div>
                    </div>
                    <div className="content">
                        <h1 className="text-[32px] mb-4 tracking-wider exo-2-bold soft-red text-center">Correspondent's message</h1>
                        <div className="flex flex-col gap-6 items-center">
                            <div>
                                <p className='teal sevillana-bold tracking-widest text-lg text-center'>I shall instruct you and teach you the way to go; I shall not take my eyes off you.</p>
                                <p className='teal text-lg sevillana-bold tracking-widest text-center mt-2'>– Psalm 32:8</p>
                            </div>
                            <p className="text-justify quicksand-bold dark-bluish tracking-wide">Dear Knowledge Seekers,</p>
                            <p className="text-justify quicksand dark-bluish">Education is a shared commitment between dedicated mentors, motivated learners and enthusiastic parents with high expectations. At BISHOP CORRIE we try to give attention to their holistic development. I believe that each child is unique, a miracle of God’s creation.
                                In the 175+ years of existence, we have had a long history of eminent headmasters, qualified and caring teachers, and successful students who have gone on to serve God and Society, and glorified the name of the school in the process. The school is constantly evolving and embracing new trends . I assure that I will put forth my best efforts to administrate effectively & efficiently.</p>
                            <p className="text-justify quicksand dark-bluish">I affectionately welcome you all to this great school of higher learning and assure you of a nurturing and caring environment that will see all of you blossom into empowered and sensitive human beings, celebrating your success and watching you grow…</p>
                            <p className="text-justify quicksand-bold teal corres-mobile-blue-text tracking-wide text-lg">Best wishes for glorious and promising future to Corriens. </p>
                            <p className="text-justify quicksand-bold teal corres-mobile-blue-text tracking-wide text-lg">"Learn how to add value to it and commit to a lifetime of learning!”</p>
                        </div>
                        <div className="flex flex-col float-right items-center mt-8">
                            <p className="quicksand dark-bluish mb-2 tracking-wide">Your beloved,</p>
                            <p className=" quicksand-bold dark-bluish tracking-wide mb-2">Y DANIEL YESUPATHAM</p>

                            <p className="montserrat dark-bluish text-sm">M.Com., M.Ed., M.Phil.,</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}