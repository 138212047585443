

export default function HeritageClub(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Heritage Club</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mrs. Teacher</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Cultural Preservation:</span> Instilling an appreciation for historical and cultural heritage, encouraging students to value and protect it for future generations.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Historical Awareness:</span> Educating students about the significance of historical events, places, and traditions, fostering a deeper understanding of their cultural roots.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Respect for Diversity:</span> Promoting respect for the diverse cultural backgrounds and traditions within the community, encouraging inclusivity and unity.</li>
                </ul>
            </div>
        </div>
        <div>
                {/* <ShortGallery /> */}
        </div>
        </>
    )
}