import { Card, Timeline, Button } from "flowbite-react";
import PageHeader from "../../components/pageHeader";
import school from "../../assets/about-image.png";
import { useState } from "react";
import SchoolPrayer from "../../components/schoolPrayer";
import SchoolClubs from "../../components/schoolClubs";
import SchoolHeritage from "../../components/schoolHeritage";
import SchoolMission from "../../components/schoolMission";
import SchoolValues from "../../components/schoolValues";
import SchoolHouses from "../../components/schoolHouses";
import { Carousel } from "flowbite-react";
import './about.css';

export default function About() {

    const [selectedContent, setSelectedContent] = useState('schoolHeritage');

    const content = {
        schoolHeritage: <SchoolHeritage />,
        schoolValues: <SchoolValues />,
        schoolPrayer: <SchoolPrayer />,
        schoolClubs: <SchoolClubs />,
        schoolVision: <SchoolMission />,
        schoolHouses: <SchoolHouses />
      };

    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"About Us"} />
            </div>
            <div className="mb-10">
               <div className="flex flex-col gap-10 mobile_gap">
                    <div className="image-container overflow-hidden w-full h-auto">
                        <img src={school} alt="image not loaded" className="w-full h-auto object-cover mobile_about_image" style={{ objectPosition: 'center 50%' }}/>
                    </div>
                    <div className="flex mx-20">
                        <div className="left-side w-1/4">
                            <div className="list-bar">
                            <ul className="flex flex-col justify-evenly montserrat dark-bluish about_school">
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white ${selectedContent === 'schoolHeritage' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolHeritage')}>School Heritage</li>
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white  ${selectedContent === 'schoolValues' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolValues')}>School Values</li>
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white  ${selectedContent === 'schoolPrayer' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolPrayer')}>School Prayer</li>
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white  ${selectedContent === 'schoolClubs' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolClubs')}>School Clubs</li>
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white  ${selectedContent === 'schoolVision' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolVision')}>School Vision</li>
                                <li className={`border-b  p-6 cursor-pointer transition-colors duration-200 hover:bg-[#EF6F56] hover:text-white  ${selectedContent === 'schoolHouses' ? 'bg-[#EF6F56] text-white' : ''}`} onClick={() => setSelectedContent('schoolHouses')}>School Houses</li>

                            </ul>
                            </div>
                        </div>
                        <div className="components w-3/4 ml-14 about_school">
                            <div className="">
                                {content[selectedContent]}
                            </div>
                        </div>
                    </div>
                    <div className="w-[80%] mx-auto flex flex-col mobile_about_school">
                        <SchoolHeritage />
                        <SchoolPrayer />
                        <SchoolValues />
                        <SchoolClubs />
                        <SchoolMission />
                        <SchoolHouses />

                    </div>
               </div>
            </div>
        </>
    )
}