import Home from './pages/home';
import './App.css';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Contact from './pages/contact/contact';
import About from './pages/about/about';
import Sponsors from './pages/sponsor/sponsors';
import Principal from './pages/principal/principal';
import EventsPage from './pages/events/events';
import Staff from './pages/staff/staff';
import SideBar from './components/sidebar';
import PageFooter from './components/footer';
import CorresPage from './pages/corresPage';
import Admission from './pages/admission/admission';
import Academics from './pages/academics';
import OurClubs from './pages/clubs/clubs';
import Facilities from './pages/facilities';
import NotFoundPage from './pages/notFoundPage';

export default function App() {
  return (
    <div className="App">
      <SideBar />
       <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/donate" element={<Sponsors />} />
            <Route path="/events" element={<EventsPage />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/principal" element={<Principal />} />
            <Route path="/admission" element={<Admission />} />
            <Route path="/academics" element={<Academics />} />
            <Route path="/correspondent" element={<CorresPage />} />
            <Route path="/clubs" element={<OurClubs />} />
            <Route path="/facilities" element={<Facilities />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
        <PageFooter />
    </div>
  );
}

