import { Rating } from "flowbite-react";
import CommonPerson from '../assets/random_person.png';

export default function Testimonials() {
  return(
    <div className="flex flex-col items-center bg-[#f5f5f5] p-9">
      <div className="text-center">
          <h2 className="text-[6vmin] exo-2 text-[#0e7490]">Echoes of Excellence</h2>
          <p className="p-4 w-full text-center quicksand text-[#37474f]">These alumni have shared their experiences to reflect the lasting impact our school has had on their lives. Explore their testimonials to understand why our school is a place where students thrive and form lifelong connections.</p>
      </div>
      <div className="flex flex-row mt-9 justify-evenly w-full review_mobile">
          <div className="bg-gray-50 shadow-2xl w-1/4 h-190 rounded-lg flex flex-col items-center p-6">
              <img src={CommonPerson} alt="Danny" className="rouneded-full w-16 h-16 m-4"/>
              <p className="text-[20px] exo-2 text-[#0e7490]">Daniel James Jewell</p>
              <p className="montserrat text-[#37474f]">Full Stack Developer</p>
              <Rating className="p-2">
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star filled={false} />
              </Rating>
              <p className="text-center dark-bluish quicksand">
              <svg
                className="mx-auto mb-3 h-6 w-6 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 14"
              >
                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
              </svg>
              Attending Bishop Corrie was a transformative experience. The welcoming community and dedicated teachers not only nurtured my growth but also sparked a lifelong love for learning that continues to influence my career.</p>
          </div>
          <div className="bg-gray-50 shadow-2xl w-1/4 h-190 rounded-lg flex flex-col items-center p-6">
              <img src={CommonPerson} alt="Josh" className="rouneded-full w-16 h-16 m-4"/>
              <p className="text-[20px] exo-2 text-[#0e7490]">Yogeshwaran S</p>
              <p className="montserrat text-[#37474f]">CA</p>
              <Rating className="p-2">
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
              </Rating>
              <p className="text-center quicksand">
              <svg
                className="mx-auto mb-3 h-6 w-6 text-gray-400 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 14"
              >
                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
              </svg>
              Bishop Corrie provided a well-rounded education, balancing academic excellence with personal development. The challenging coursework, engaging activities, and supportive mentorship equipped me with the skills and confidence to face any challenge.</p>
          </div>
          <div className="bg-gray-50 shadow-2xl w-1/4 h-190 rounded-lg flex flex-col items-center p-6">
              <img src={CommonPerson} alt="common" className="rouneded-full w-16 h-16 m-4"/>
              <p className="text-[20px] exo-2 text-[#0e7490]">Subish S</p>
              <p className="montserrat text-[#37474f]">Salesforce Developer</p>
              <Rating className="p-2">
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star />
                <Rating.Star filled={false} />
              </Rating>
              <p className="text-center quicksand">
              <svg
                className="mx-auto mb-3 h-6 w-6 text-gray-400 dark:text-gray-600"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 18 14"
              >
                <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z" />
              </svg>
              My time at Bishop Corrie was pivotal in shaping my achievements. The friendships and lessons from those years still influence my life. I’m deeply grateful for the opportunities Bishop Corrie provided and highly recommend it for a nurturing educational experience.</p>
          </div>
      </div>
    </div>
  )
}