import { HiClipboardList } from "react-icons/hi";
import PageHeader from "../../components/pageHeader";
import { Card, List, Tabs, Button } from "flowbite-react";
import { useEffect, useState } from "react";
import store from "../../firebase";
import { collection, getDocs, query } from "firebase/firestore";
import './sponsor.css';

const studentDetails = collection(store, 'sponsors');


export default function Sponsors() {

    const [studentData, setStudentData] = useState([]);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(studentDetails);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data() });
                });
                displayData(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])

    function displayData(data){
        const groupedById = data.reduce((accumulator, item) => {
            for (const [id, doc] of Object.entries(item)) {
                if (!accumulator[doc.class]) {
                    accumulator[doc.class] = [];
                  }
                  accumulator[doc.class].push(doc);
              }
            return accumulator;
          }, {});
          const entries = Object.entries(groupedById);
          const firstThree = entries.slice(0, 3);
          const rest = entries.slice(3);
          const rearrangedEntries = [...rest, ...firstThree];
          const rearrangedGroupedById = Object.fromEntries(rearrangedEntries);
          setStudentData(rearrangedGroupedById);
    }

    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Make A Gift"} />
            </div>
            <div className="flex justify-center board_bg h-svh w-[80%] ml-auto mr-auto my-6 mobile_board">
                <div className="board_layer text-white text-left p-14 leading-loose flex justify-center flex-col">
                    <p className="cedarville-cursive-regular text-[6vmin] text-center mobile_board_title">Help Bright Minds Shine!!</p>
                    <p className="quicksand text-justify m-6 text-[3vmin] mobile_board_subtitle">Exceptional students are ready to achieve great things but need financial help to do so. Your donation can provide crucial assistance to these deserving individuals, allowing them to focus on their studies and excel.</p>
                    <p className="exo-2 mx-6 text-[3vmin] mobile_board_subtitle">Give the gift of opportunity.</p>
                    <p className="exo-2 mx-6 text-[3vmin] mobile_board_subtitle">Your generosity can make a big difference.</p>
                    <p className="exo-2 mx-6 text-[3vmin] mobile_board_subtitle">Donate today and help these deserving students succeed.</p>
                </div>
            </div>
            <div className="my-16 mobile-section">
            <div className="flex flex-col w-[80%] mobile-width-adjust mx-auto">
                <div className="flex flex-row w-[80%] justify-center mobile-width-adjust items-center ml-auto mr-auto mobile-sponsor-button">
                    
                        <h2 className="text-[5vmin] exo-2 text-[#0e7490] text-center m-6 mobile-sponsor-title">Empowering Top Students</h2>
                    <Button className="focus:ring-transparent w-[45vmin] sponsor-btn h-[8vmin] flex flex-col items-center m-10 montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state">
                        <a href="/contact" className="text-[4vmin] py-0">Make a Gift</a> 
                        <svg className="-mr-1 ml-2 mt-0.5 h-4 w-4  hide_tabs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                        <path
                            fillRule="evenodd" 
                            d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                            clipRule="evenodd"
                        />
                        </svg>
                    </Button>
                    </div>

                    <p className="text-[3vmin] w-[60%] mobile-width-adjust mx-auto quicksand dark-bluish text-justify mb-6">Support our mission by making a monetary donation. We are seeking donations for the students listed below. Click on the button above and contact us, we will guide you on further steps.</p>

            </div>
            <Tabs aria-label="Tabs with icons"  variant="underline" className="flex-row w-full h-auto border-b-0 custom_padding hide_tabs">
                    {Object.entries(studentData).map(([group, items]) => (
                        <Tabs.Item active key={group} title={group} icon={HiClipboardList} className="">
                        <div className="flex flex-row justify-start flex-wrap">
                        {Array.isArray(items) && items.length > 0 ? (
                            <div className="flex flex-row flex-wrap gap-8 w-[100%]">
                            {items.map(item => 
                                 (item.name ? <Card href="#" className="w-[44%]" key={item.id}>
                                       <h5 className="text-xl exo-2-bold tracking-tight dark-bluish border-b-4 border-[#EF6F56] p-1">
                                            {item.name}
                                        </h5>
                                        <List unstyled className="max-w-md divide-y divide-gray-200 dark:divide-gray-700 dark-bluish">
                                            <List.Item className="pb-1 sm:pb-0">
                                                <div className="flex items-center space-x-10 rtl:space-x-reverse">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Section</p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">{item.section}</div>
                                                </div>
                                            </List.Item>
                                            <List.Item className="pb-1 sm:pb-0">
                                                <div className="flex items-center space-x-10 rtl:space-x-reverse">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Parent Name</p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">{item.parent_name == null ? "" : item.parent_name}</div>
                                                </div>
                                            </List.Item>
                                            <List.Item className="pb-1 sm:pb-0">
                                                <div className="flex items-center space-x-10 rtl:space-x-reverse">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Single Parent</p>
                                                </div>
                                                <label className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                                    {item.single_parent == true ?  <span className="checkmark">&#10003;</span> : <span className="crossmark">&#10007;</span>}
                                                </label>
                                                </div>
                                            </List.Item>
                                            <List.Item className="pb-1 sm:pb-0">
                                                <div className="flex items-center space-x-10 rtl:space-x-reverse">
                                                <div className="min-w-0 flex-1">
                                                    <p className="truncate text-sm font-medium text-gray-900 dark:text-white">Phone Number</p>
                                                </div>
                                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">{item.number}</div>
                                                </div>
                                            </List.Item>
                                        </List>
                                 </Card> :
                                 <p className="flex flex-row justify-center my-20 w-[100%]">No items available</p>
                            ))}
                            </div>
                        ) : (
                            <p className="flex flex-row justify-center">No items available</p>
                        )}
                        </div>
                </Tabs.Item> ))}
            </Tabs> 
            <div className="w-[90%] mx-auto mb-10 overflow-y-scroll show_tabs flex flex-row flex-wrap justify-start gap-x-1 gap-y-2 shadow-2xl">
                {Object.entries(studentData).map(([group, items]) => (
                    // <div className="flex flex-row justify-start flex-wrap">
                    Array.isArray(items) && items.length > 0 && (
                         items.map(item => 
                            (item.name && 
                                <div className="bg-[#f5f5f5] w-full p-6">
                                    <div className="flex flex-col w-[100%] m-0 p-0">
                                        <div className="flex flex-row justify-start">
                                            <p className="exo-2-bold tracking-wide teal">Name : </p>
                                            <p className="ml-1 tracking-wide dark-bluish">{item.name}</p>
                                        </div>
                                        <div className="flex flex-row justify-start">
                                            <p className="exo-2-bold tracking-wide teal">Section : </p>
                                            <p className="ml-1 tracking-wide dark-bluish">{item.section}</p>
                                        </div>
                                        <div className="flex flex-row justify-start">
                                            <p className="exo-2-bold tracking-wide teal">Parent : </p>
                                            <p className="ml-1 tracking-wide dark-bluish">{item.parent_name}</p>
                                        </div>
                                        <div className="flex flex-row justify-start">
                                            <p className="exo-2-bold tracking-wide teal">Single Parent : </p>
                                            <p className="ml-1 tracking-wide dark-bluish">{item.single_parent == true ?  <span className="checkmark">&#10003;</span> : <span className="crossmark">&#10007;</span>}</p>
                                        </div>
                                        <div className="flex flex-row justify-start">
                                            <p className="exo-2-bold tracking-wide teal">Phone Number : </p>
                                            <p className="ml-1 tracking-wide dark-bluish">{item.number}</p>
                                        </div>
                                    </div>
                                </div>
                       )))))}
                       
            </div>
            {/* <Button className="focus:ring-transparent w-52 my-10 mx-auto montserrat bg-[#EF6F56] show_tabs hover:scale-80 hover:-translate-y-1 hover_state">
                        <a href="/contact" className="text-[3vmin] p-2">Make a Gift</a> 
                    </Button> */}
            </div>
        </>

    )
}