// import shortGallery1 from "../assets/short-gallery-1.jpeg"
// import shortGallery2 from "../assets/short-gallery-2.jpeg"
// import shortGallery3 from "../assets/short-gallery-3.jpeg"
// import shortGallery4 from "../assets/short-gallery-4.jpeg"
// import shortGallery5 from "../assets/short-gallery-5.jpeg"
// import shortGallery6 from "../assets/short-gallery-6.jpeg"
// import shortGallery7 from "../assets/short-gallery-7.jpeg"
// import shortGallery8 from "../assets/short-gallery-8.jpeg"

// import { Carousel } from "flowbite-react";

// export default function SchoolInfo(){
//     return (
//         <div className="h-[500px] overflow-hidden flex justify-center">
//              <div className="h-full w-[80%] sm:h-full xl:h-[100vmin] xl:w-[80%] 2xl:h-[100vmin] 2xl:w-[80%]">
//                     <Carousel leftControl=" " rightControl=" " indicators={false} slideInterval={3000}>
//                         <img src={shortGallery1} alt="..." className="h-full w-full object-cover object-[center-top]"/>
//                         <img src={shortGallery2} alt="..." className="h-full w-full object-fit object-[center]" />
//                         <img src={shortGallery3} alt="..." className="h-full w-full object-fit object-[center]" />
//                         <img src={shortGallery4} alt="..." className="h-full w-full object-cover object-[bottom]" />
//                         <img src={shortGallery6} alt="..." className="h-full w-full object-fit object-[center]"/>
//                         <img src={shortGallery5} alt="..." className="h-full w-full object-fit object-[center]" />
//                         <img src={shortGallery7} alt="..." className="h-full w-full object-fit object-[center]" />
//                         <img src={shortGallery8} alt="..." className="h-full w-full object-fit object-[center-bottom]" />
//                     </Carousel>
//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect, useRef  } from 'react';
import shortGallery1 from "../assets/short-gallery-1.jpeg"
import shortGallery2 from "../assets/short-gallery-2.jpeg"
import shortGallery3 from "../assets/short-gallery-3.jpeg"
import shortGallery4 from "../assets/short-gallery-4.jpeg"
import shortGallery5 from "../assets/short-gallery-5.jpeg"
import shortGallery6 from "../assets/short-gallery-6.jpeg"
import shortGallery7 from "../assets/short-gallery-7.jpeg"
import shortGallery8 from "../assets/short-gallery-8.jpeg"
import shortGallery9 from "../assets/timeline_img_1.jpeg";

const photos = [
    shortGallery1,
    shortGallery2,
    shortGallery3,
    shortGallery4,
    shortGallery5,
    shortGallery6,
    shortGallery7,
    shortGallery8,
    shortGallery9
  ];

  const ShortGallery = () => {
    const [loadedPhotos, setLoadedPhotos] = useState([]);
    const gridRef = useRef(null);
  
    useEffect(() => {
        const handleIntersection = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              photos.forEach((photo, index) => {
                setTimeout(() => {
                  setLoadedPhotos((prev) => {
                    if (!prev.includes(photo)) {
                      return [...prev, photo];
                    }
                    return prev;
                  });
                }, index * 300);
              });
              observer.unobserve(entry.target);
            }
          });
        };
    
        const observer = new IntersectionObserver(handleIntersection, {
          root: null,
          rootMargin: '0px',
          threshold: 0.8,
        });
    
        if (gridRef.current) {
          observer.observe(gridRef.current);
        }
    
        return () => {
          if (gridRef.current) {
            observer.unobserve(gridRef.current);
          }
        };
      }, []);
  
    return (
      <div className="short-gallery"  ref={gridRef}>
        {loadedPhotos.map((photo, index) => (
          <div key={index} className="photo shadow-2xl">
            <img src={photo} alt={`Photo ${index + 1}`} />
          </div>
        ))}
      </div>
    );
  };
  
export default ShortGallery;