import React from 'react';
import { Accordion, Card } from 'flowbite-react';
import { FaBook, FaFlask, FaCalculator, FaGraduationCap, FaPuzzlePiece, FaChalkboardTeacher, FaSchool, FaRunning } from 'react-icons/fa';

const Curriculum = () => {
  return (
    <div className="">
      {/* Title and Introduction */}
      <header className="mb-8 text-center">
        <h1 className="text-[5vmin] exo-2-bold tracking-wider teal mb-4">Our Curriculum</h1>
        <div className="border-l-4 border-r-4 border-teal-500 px-6 py-4 mx-auto max-w-2xl">
          <p className="text-[3vmin dark-bluish quicksand]">
            Our curriculum is designed to nurture and develop students' intellectual and personal growth through a comprehensive learning journey.
          </p>
        </div>
      </header>

      {/* Curriculum Sections */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
        <Card className="bg-gradient-to-r from-teal-400 to-blue-500 text-white p-6">
          <div className="flex items-center">
            <FaBook className="text-4xl mr-4" />
            <div>
              <h2 className="text-[4vmin] exo-2-bold mb-2">Primary School</h2>
              <p className="text-sm montserrat tracking-wide">Grades 1-5</p>
            </div>
          </div>
          <p className="mt-4 text-[2.5vmin] quicksand text-center">Laying the foundation for lifelong learning with engaging and interactive lessons.</p>
        </Card>
        <Card className="bg-gradient-to-r from-yellow-400 to-orange-500 text-white p-6">
          <div className="flex items-center">
            <FaSchool className="text-4xl mr-4" />
            <div>
              <h2 className="text-[4vmin] exo-2-bold mb-2">Middle School</h2>
              <p className="text-sm montserrat tracking-wide">Grades 6-8</p>
            </div>
          </div>
          <p className="mt-4 text-[2.5vmin] quicksand text-center">Developing critical thinking and problem-solving skills through project-based learning.</p>
        </Card>
        <Card className="bg-gradient-to-r from-purple-400 to-pink-500 text-white p-6">
          <div className="flex items-center">
            <FaChalkboardTeacher className="text-4xl mr-4" />
            <div>
              <h2 className="text-[4vmin] exo-2-bold mb-2">High School</h2>
              <p className="text-sm montserrat tracking-wide">Grades 9-10</p>
            </div>
          </div>
          <p className="mt-4 text-[2.5vmin] quicksand text-center">Preparing students for higher education with a strong emphasis on academics.</p>
        </Card>
        <Card className="bg-gradient-to-r from-green-400 to-teal-500 text-white p-6">
          <div className="flex items-center">
            <FaGraduationCap className="text-4xl mr-4" />
            <div>
              <h2 className="text-[4vmin] exo-2-bold mb-2">Higher Secondary School</h2>
              <p className="text-sm montserrat tracking-wide">Grades 11-12</p>
            </div>
          </div>
          <p className="mt-4 text-[2.5vmin] quicksand text-center">Specializing in chosen subjects and preparing for college and career readiness.</p>
        </Card>
      </div>

      {/* Content Structure in Accordion */}
      <div className="mb-12">
        <Accordion alwaysOpen={true}>
          <Accordion.Panel>
            <Accordion.Title className='teal montserrat-bold tracking-wide'>
              <FaPuzzlePiece className="inline-block mr-[3vmin] text-2xl soft-red" />
              Grade 1-5: Primary School
            </Accordion.Title>
            <Accordion.Content>
              <div className="space-y-4">
                <p className="quicksand"><span className="teal montserrat">Subjects:</span> Mathematics, Science, Social Studies, Language Arts</p>
                <p className="quicksand"><span className="teal montserrat">Learning Objectives:</span> Building strong foundational skills in core subjects.</p>
                <p className="quicksand"><span className="teal montserrat">Teaching Methods:</span> Play-based learning, Hands-on activities.</p>
                <p className="quicksand"><span className="teal montserrat">Assessments:</span> Written tests, Portfolio assessments.</p>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title className='teal montserrat-bold tracking-wide'>
              <FaBook className="inline-block mr-[3vmin] text-2xl soft-red" />
              Grade 6-8: Middle School
            </Accordion.Title>
            <Accordion.Content>
              <div className="space-y-4">
                <p className="quicksand"><span className="teal montserrat">Subjects:</span> Mathematics, Social Science, Environmental Science, Physical Education, Language, Value Education.</p>
                <p className="quicksand"><span className="teal montserrat">Learning Objectives:</span> Enhancing critical thinking and problem-solving skills.</p>
                <p className="quicksand"><span className="teal montserrat">Teaching Methods:</span> Group projects, Interactive learning.</p>
                <p className="quicksand"><span className="teal montserrat">Assessments:</span> Quizzes, Oral presentations, Projects.</p>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title className='teal montserrat-bold tracking-wide'>
              <FaFlask className="inline-block soft-red mr-[3vmin] text-2xl" />
              Grade 9-10: High School
            </Accordion.Title>
            <Accordion.Content>
              <div className="space-y-4">
                <p className="quicksand"><span className="teal montserrat">Subjects:</span> Advanced Mathematics, HTML, C Programming, World History, Geography, Economics, Language, Science.</p>
                <p className="quicksand"><span className="teal montserrat">Learning Objectives:</span> Preparing students for higher secondary education.</p>
                <p className="quicksand"><span className="teal montserrat">Teaching Methods:</span> Lectures, Lab experiments, Field trips.</p>
                <p className="quicksand"><span className="teal montserrat">Assessments:</span> Exams, Lab reports, Research papers.</p>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title className='teal montserrat-bold tracking-wide'>
              <FaGraduationCap className="inline-block mr-[3vmin] text-2xl soft-red" />
              Grade 11-12: Higher Secondary School
            </Accordion.Title>
            <Accordion.Content>
              <div className="space-y-4">
                <p className="quicksand"><span className="teal montserrat">Subjects:</span> Matrix, Calculus, Biology, Economics, HTML, Python, Physics, Chemistry, French.</p>
                <p className="quicksand"><span className="teal montserrat">Learning Objectives:</span> Specialization in subjects of interest, College preparation.</p>
                <p className="quicksand"><span className="teal montserrat">Teaching Methods:</span> Seminars, Workshops, Independent study.</p>
                <p className="quicksand"><span className="teal montserrat">Assessments:</span> Final exams, Term papers, Project work.</p>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
          <Accordion.Panel>
            <Accordion.Title className='teal montserrat-bold tracking-wide'>
              <FaRunning className="inline-block mr-[3vmin] soft-red text-2xl" />
              Physical Education Curriculum
            </Accordion.Title>
            <Accordion.Content>
              <div className="space-y-4">
                <p className="quicksand"><span className="teal montserrat">Physical Education:</span> Encouraging fitness and teamwork through various sports and activities.</p>
                <p className="quicksand"><span className="teal montserrat">Activities:</span> Calisthenics, Track and Field, Dumbells Exercises, Lezium, Hoops Drill, Indian Club Drill.</p>
                <p className="quicksand"><span className="teal montserrat">Sports:</span> Basket Ball, Cricket, Volley Ball, Kho-Kho, Kabbadi, Throwball, Tenecoid, Indoor sports.</p>
                <p className="quicksand"><span className="teal montserrat">Methods:</span> Practice sessions, Inter-school competitions, Fitness assessments.</p>
              </div>
            </Accordion.Content>
          </Accordion.Panel>
        </Accordion>
      </div>

      {/* Sports */}
      {/* <section className="bg-teal-500 p-8 rounded-lg text-white">
      <h2 className="text-[5vmin] exo-2-bold mb-6 tracking-wide text-center">Sports</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <Card className="bg-white text-center rounded-lg overflow-hidden shadow-lg">
          <img src="https://via.placeholder.com/200" alt="Basketball" className="w-full h-40 object-cover mb-4" />
          <div className="p-4">
            <h3 className="text-[4vmin] soft-red exo-2-bold tracking-wide mb-2">Basketball</h3>
            <p className="quicksand text-[3vmin] text-center dark-bluish">Our basketball program focuses on teamwork, strategy, and physical endurance.</p>
          </div>
        </Card>
        <Card className="bg-white text-center rounded-lg overflow-hidden shadow-lg">
          <img src="https://via.placeholder.com/200" alt="Volleyball" className="w-full h-40 object-cover mb-4" />
          <div className="p-4">
            <h3 className="text-[4vmin] soft-red exo-2-bold tracking-wide mb-2">Volleyball</h3>
            <p className="quicksand text-[3vmin] text-center dark-bluish">Develop your skills in volleyball with our dynamic and competitive training sessions.</p>
          </div>
        </Card>
        <Card className="bg-white text-center rounded-lg overflow-hidden shadow-lg">
          <img src="https://via.placeholder.com/200" alt="Throwball" className="w-full h-40 object-cover mb-4" />
          <div className="p-4">
            <h3 className="text-[4vmin] soft-red exo-2-bold tracking-wide mb-2">Throwball</h3>
            <p className="quicksand text-[3vmin] text-center dark-bluish">Enjoy the fast-paced action of throwball and improve your agility, strength and coordination.</p>
          </div>
        </Card>
        <Card className="bg-white text-center rounded-lg overflow-hidden shadow-lg">
          <img src="https://via.placeholder.com/200" alt="Kho-Kho" className="w-full h-40 object-cover mb-4" />
          <div className="p-4">
            <h3 className="text-[4vmin] soft-red exo-2-bold tracking-wide mb-2">Kho-Kho</h3>
            <p className="quicksand text-[3vmin] text-center dark-bluish">Experience the traditional sport of Kho-Kho, emphasizing speed, agility, strategy, and teamwork.</p>
          </div>
        </Card>
      </div>
    </section> */}

    </div>
  );
};

export default Curriculum;
