"use client";

import { Button, Card } from "flowbite-react";

export default function BriefAbout() {
  return (
    <div className="flex flex-row gap-8 brief_mobile">
        <div className="w-2/3 h-5/6 legacy"> 
            <Card className="min-w-sm pb-3 ml-7 mobile_card">
            <h5 className="text-[6vmin] tracking-tight text-[#0e7490] exo-2">
                A Legacy of Excellence: The Journey of Bishop Corrie School
            </h5>
            <p className="quicksand mobile-legacy-content text-[#37474f]">
                Founded in 1834 as Parental Academy in Semnu Doss Street, now George Town, the school soon became Madras Grammar School in 1836. In 1869, it was renamed Bishop Corrie School, honoring its first president, Rt. Rev. Daniel Corrie, Bishop of Madras. Despite financial challenges, the school's continuity remained unbroken. The high school moved to Doveton School, Vepery, and the middle school to Chapel Church Street, becoming Bishop Corrie Middle School.

                In 1958, the school regained its high school status and was renamed Bishop Corrie High School. By 1978, it had evolved into C.S.I. Bishop Corrie Anglo Indian Higher Secondary School. With a rich tradition of excellence, the school continues to provide a well-rounded education, nurturing students to become responsible citizens of India.
            </p>
            <Button href="/about" className="focus:ring-transparent w-52 montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state">
                Read more
                <svg className="-mr-1 ml-2 mt-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                />
                </svg>
            </Button>
            </Card>
        </div>
        <div className="w-1/3 mr-7 tree rounded-md">
        </div>
    </div>
  );
}
