import React, { useState, useEffect } from 'react';
import Tooltip from './tooltip';
import Students from '../assets/tooltip-image.jpeg';

const ImageWithTooltips = () => {
    const [currentTooltip, setCurrentTooltip] = useState(null);
    const [tooltips] = useState([
        { text: 'sir, you are a scholar in chemistry . It is not easy to love Chemistry but you taught me how easy it could be to love this subject and also pass with flying colours', position: { x: 80, y: 10 } },
        { text: 'Your dedication, passion, and commitment have made a positive impact on our school community.', position: { x: 640, y: 40 } },
        { text: 'We are grateful for your leadership, Principal! I want to express my deep appreciation for your visionary leadership.', position: { x: 360, y: 75 } },
        { text: 'Your impact on our lives can never be forgotten. You always inspire us to do our best for the students. ', position: { x: 530, y: 30 } },
        { text: 'Thank you so much Sir, for being such a great and exceptional leader, for managing the entire school and us distinctively.', position: { x: 820, y: 30 } }

        // Add more tooltips as needed
    ]);

    useEffect(() => {
        let index = 0;
        const showNextTooltip = () => {
            if (index < tooltips.length) {
                setCurrentTooltip(tooltips[index]);
                index = (index + 1) % tooltips.length;
                setTimeout(() => setCurrentTooltip(null), 10000); // Hide after 2 seconds
                setTimeout(showNextTooltip, 5000); // Show next tooltip after 3 seconds
            }
        };
        showNextTooltip();
        return () => clearTimeout(showNextTooltip);
    }, [tooltips]);

    return (
        <div style={{ position: 'relative' }} className='w-auto h-[400px] overflow-hidden tooltip-container'>
            <img src={Students} alt="Students" className="student_image w-auto brightness-50 h-auto object-cover" />
            {currentTooltip && (
                <Tooltip className="tooltip"
                    text={currentTooltip.text}
                    position={currentTooltip.position}
                    visible={!!currentTooltip}
                />
            )}
        </div>
    );
};

export default ImageWithTooltips;