import PhotoGrid from "../photoGrid";
import td1 from '../../assets/clubs skl/TD-01.jpeg'
import td2 from '../../assets/clubs skl/TD-02.jpeg'
import td3 from '../../assets/clubs skl/TD-03.jpeg'



export default function TamilDramatics(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">Tamil Dramatics Club</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mr. Arun Prasath</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Cultural Heritage:</span> Promoting an understanding and appreciation of Tamil culture and traditions through the art of drama.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Expression:</span> Encouraging students to explore and express themselves through Tamil language and performance arts.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Collaboration:</span> Teaching the importance of working together to create impactful and meaningful theatrical productions.</li>
                </ul>
            </div>
        </div>
        <div>
                <PhotoGrid photos={[td1,td2,td3]} />
            </div>
        </>
    )
}