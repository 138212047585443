import CountUp from 'react-countup';
import React, { useRef, useState, useEffect } from 'react';


export default function Counter({ start, end, title }){
    const [inView, setInView] = useState(false);
    const sectionRef = useRef(null);
    const countUpRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setInView(true);
                } else {
                    setInView(false);
                }
            },
            {
                threshold: 0.9, // Adjust this threshold as needed
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        // Cleanup
        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (inView) {
            // Trigger the count-up reset by using the ref
            if (countUpRef.current) {
                countUpRef.current.start();
            }
        }
    }, [inView]);


    return(
        <div ref={sectionRef} className="number ">
            {inView && (<>
                <CountUp className="counter exo-2-bold" start={start} end={end} duration={3} suffix='+' ref={countUpRef} />
                <span className='mt-6 montserrat'>{title}</span> 
                </>
            )}
        </div>
    )
}