import PhotoGrid from "../photoGrid"
import ED1 from '../../assets/clubs skl/ED-01.jpeg'


export default function EnglishDramatics(){
    return(
        <>
        <div className="flex flex-col items-start gap-12 mobile_clubs_gap">
            <div>
                <h1 className="text-[5vmin] teal exo-2-bold tracking-wider">English Dramatics Club</h1>
                <p className="text-[3vmin] mt-4 dark-bluish montserrat">Club Co-ordinator: <span className="soft-red">Mrs. Gladys Thabitha</span></p>
            </div>
            <div>
                <h1 className="text-[4vmin] mb-4 teal exo-2-bold tracking-wider">Core Values</h1>
                <ul className="list-disc pl-5 leading-loose custom-bullet text-justify">
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Communication:</span> Enhancing students' ability to express themselves clearly and creatively through English drama.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Literary Appreciation:</span> Fostering a love for literature and the performing arts, encouraging students to explore diverse narratives and characters.</li>
                    <li className="dark-bluish quicksand"><span className="soft-red montserrat-bold">Confidence:</span> Encouraging consistent practice and dedication to improve vocal performance and teamwork within the choir.</li>
                </ul>
            </div>
        </div>
        <div>
            <PhotoGrid photos={[ED1]} />
        </div>
        </>
    )
}