import { Card } from "flowbite-react"
import hoops from "../assets/hoopsdrill.mp4"
import silambam from "../assets/silambam.mp4"
import dumbells from "../assets/dumbells.mp4"
import lezim from "../assets/lezim.mp4"
import karate from "../assets/karate.mp4"

export default function ExtraCurricular(){
    return(
        <>
        <section className="mb-12">
        <h2 className="text-[5vmin] teal exo-2-bold tracking-wider mb-6 text-center">Extracurricular Activities</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6">
          <Card className="p-0 rounded-lg overflow-hidden">
            <video autoPlay loop className="w-full h-64 object-cover">
              <source src={silambam} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="p-4 teal-bg text-white">
              <h3 className="text-[3.5vmin] exo-2-bold tracking-wide mb-2">Silambam</h3>
              <p className="text-[2.5vmin] quicksand">Silambam is a daily practice where students learn the ancient martial art, enhancing their agility, strength, and discipline.</p>
            </div>
          </Card>
          <Card className="p-0 rounded-lg overflow-hidden">
            <video autoPlay loop className="w-full h-64 object-cover">
              <source src={lezim} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="p-4 teal-bg text-white">
              <h3 className="text-[3.5vmin] exo-2-bold tracking-wide mb-2">Lezim</h3>
              <p className="text-[2.5vmin] quicksand">Lezim is a vibrant daily activity where students engage in rhythmic exercises, combining tradition with physical fitness.</p>
            </div>
          </Card>
          <Card className="p-0 rounded-lg overflow-hidden">
            <video autoPlay loop className="w-full h-64 object-cover">
              <source src={karate} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="p-4 teal-bg text-white">
              <h3 className="text-[3.5vmin] exo-2-bold tracking-wide mb-2">Karate</h3>
              <p className="text-[2.5vmin] quicksand">Developing discipline and physical fitness through martial arts training.</p>
            </div>
          </Card>
          <Card className="p-0 rounded-lg overflow-hidden">
            <video autoPlay loop className="w-full h-64 object-cover">
              <source src={hoops} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="p-4 teal-bg text-white">
              <h3 className="text-[3.5vmin] exo-2-bold tracking-wide mb-2">Hoops Drill</h3>
              <p className="text-[2.5vmin] quicksand">The Hoops Drill is a daily activity where students sharpen their basketball skills, focusing on teamwork, agility, and precision.</p>
            </div>
          </Card>
          <Card className="p-0 rounded-lg overflow-hidden">
            <video autoPlay loop className="w-full h-64 object-cover">
              <source src={dumbells} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="p-4 teal-bg text-white">
              <h3 className="text-[3.5vmin] exo-2-bold tracking-wide mb-2">Dumbells Drill</h3>
              <p className="text-[2.5vmin] quicksand">The Dumbbells Drill is a daily fitness routine, helping students build strength, coordination, and endurance through structured exercises.</p>
            </div>
          </Card>
        </div>
      </section>
        </>
    )
}