import schoolHeritage from "../assets/heritage-video.mp4"

export default function SchoolHeritage(){
    return(
        <>
        <div className="flex flex-col items-center gap-8">
           
            <div>
                <video className="h-[400px] mobile_video_height shadow-2xl my-8" src={schoolHeritage} autoPlay loop={true} muted alt="not found"/>
            </div>     
        </div>
        </>
    )
}