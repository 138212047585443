import {React, useRef} from 'react';
import PageHeader from "../../components/pageHeader";
import AdmissionImage from "../../assets/admission-page.png";
import Review from '../../assets/review-file.png';
import Folder from '../../assets/folder-rupee.png';
import Notepad from '../../assets/notepad.png';
import { saveAs } from 'file-saver';
import { List, Button, Textarea, Label, TextInput, Accordion } from "flowbite-react";
import { HiCheckCircle } from "react-icons/hi";
import './admission.css';
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser';


export default function Admission() {

   const steps = [
      { number: 1, title: 'Application', imageSrc: Notepad },
      { number: 2, title: 'Registration Fee', imageSrc: Folder },
      { number: 3, title: 'Application Review', imageSrc: Review },
   ];
   const downloadFeeStructure = () => {
      saveAs("/fee-structure.jpeg","Fee_Structure.jpeg")
   }

   const downloadForm = () => {
      saveAs("/Admission_form.pdf","Admission_Form.pdf")
   }
   const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        Swal.fire({
         title: 'Sending...',
         text: 'Please wait while your enquiry is being sent.',
         allowOutsideClick: false,
         didOpen: () => {
           Swal.showLoading();  // Display the loading spinner
         }
       });

        emailjs
            .sendForm('service_71lifcy', 'template_waux1jv', form.current, {
                publicKey: 'Aue7w_OvC9oVBAVDu',
            })
            .then(
                () => {
                    Swal.fire({
                        title: 'Email Sent',
                        text: 'Your Enquiry is sent. We will reach out to you soon.',
                        icon: 'success',
                        confirmButtonText: 'Cool'
                      });
                    form.current.reset();
                },
                (error) => {
                    Swal.fire({
                        title: 'Error!',
                        text: 'Error sending email, please try again',
                        icon: 'error',
                        confirmButtonText: 'Try Again'
                      });
                    console.log('FAILED...', error.text);
                    form.current.reset();
                },
            );
    };

   return (
      <>
         <PageHeader title={"Empower Your Future"} />
         <div className="w-[100%]">
            <img src={AdmissionImage} alt="admission" className="w-[100%]" />
            <div className='w-[80%] mobile-admission mx-auto my-10 bg-[#f5f5f5] p-8 border-l-4 border-[#EF6F56] quicksand'>
               <h2 className='text-center text-[5vmin] text-[#EF6F56] exo-2-bold mb-4'>Why Us?</h2>
               <p className='text-[3vmin] text-justify dark-bluish'>As parents, the most important choice you can make for your child's future is their school. School plays an important role in
                  shaping the future of a child. School is the place where your child will spend about 6-8 hours of their day. At Bishop Corrie, you can be
                  rest assured that your child has an overall growth. We ensure that your child gets the right amount of attension, advice, and direction for their
                  future. Our highly qualified teachers not only provide the necessary academic lessons, but also are capable of showing the right direction to our students.
                  Our admission process and other details are as follows, please feel free to reach out to us in case of any queries.
               </p>
               <p className='text-[4vmin] my-1 exo-2 teal'>Join us and unlock your child's future.</p>
            </div>
            <div className='fee-structure-container h-[10vmin] bg-[#EF6F56] w-[80%] mx-auto border-check flex flex-col items-center justify-center'>
               <div className='w-full px-4 flex justify-between mx-auto my-20'>
                  <h2 className='text-center text-[5vmin] tracking-wide text-white exo-2-bold'>Fee Structure</h2>
                  <div className='flex flex-col items-center justify-center'>
                     <Button onClick={downloadFeeStructure} className="focus:ring-transparent w-[30vmin] h-[5vmin] flex flex-col items-center justify-center montserrat-bold tracking-wide bg-[#EF6F56] border-2-white hover:scale-80 hover:-translate-y-1 hover_state">
                        Download
                        <svg className="-mr-1 ml-2 mt-0.5 h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                           <path
                              fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                           />
                        </svg>
                     </Button>
                  </div>
               </div>
            </div>
            <div className="admission-steps mobile-admission w-[80%] py-2 mx-auto">
               <div className="steps-container">
                  <h2 className='text-center text-[5vmin] text-[#EF6F56] exo-2-bold m-6'>Application Process</h2>
                  <div className='flex flex-row justify-evenly w-[80%] my-4 mx-auto p-4 mobile_admission_steps'>
                     {steps.map((step, index) => (
                        <div className='flex flex-col items-center' key={index}>
                           <img src={step.imageSrc} alt={step.title} className="step-image" />
                           <h3 className="step-title text-center quicksand-bold">{step.title}</h3>
                           <p className="step-number text-center montserrat">Step {step.number}</p>
                        </div>
                     ))}
                  </div>
               </div>
            </div>
            <div className='w-[80%] mobile-admission mx-auto my-10 bg-[#f5f5f5] p-4 border-l-4 border-[#EF6F56] quicksand'>
               <h2 className='text-center text-[5vmin] text-[#EF6F56] exo-2-bold m-4'>Admission & Registration Guidelines</h2>
               <p className='text-[3vmin] mb-3'>
                  <Accordion>
                     <Accordion.Panel>
                        <Accordion.Title><span className='text-[#EF6F56] exo-2-bold'>Step 1 &nbsp;</span><span className=''> Fill out the Admission form</span></Accordion.Title>
                        <Accordion.Content>
                           <p className="mb-2 dark-bluish quicksand-bold">
                              Fill out the form with your Email ID and the details for the child & parents/ guardian.
                              To apply for more than one child, please fill two admission forms with your details.
                           </p>
                           <p className="dark-bluish quicksand mb-4">
                              You can download the admission form by clicking on the <span className='italic font-semibold mx-1 text-[#fffff]'>Download</span> button below&nbsp;
                           </p>
                           <button className="w-[25%] mobile-admission-button h-[8vmin] rounded-md text-[#f5f5f5] text-[2vmin] montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state mr-auto ml-auto" onClick={downloadForm}>Download Admission Form</button>
                        </Accordion.Content>
                     </Accordion.Panel>
                     <Accordion.Panel>
                        <Accordion.Title><span className='text-[#EF6F56] exo-2-bold'>Step 2 &nbsp;</span><span> Registration Fee</span></Accordion.Title>
                        <Accordion.Content>
                           <p className="mb-2 dark-bluish quicksand-bold">
                              Once you have filled the admission form, please submit the form along with a registration fee of -  Rs 300/- for 1st to 9th ,payment of 500/- for 11 th std.
                              The form and the resgistration fee are to be submitted at the school directly.
                              Please review the form before submitting as no further changes will be allowed once the form is submitted.
                           </p>
                           <p className="dark-bluish quicksand">
                              Please note - The registration fee is non-refundable &nbsp;
                           </p>
                        </Accordion.Content>
                     </Accordion.Panel>
                     <Accordion.Panel>
                        <Accordion.Title><span className='text-[#EF6F56] exo-2-bold'>Step 3 &nbsp;</span><span> Application Review</span></Accordion.Title>
                        <Accordion.Content>
                           <p className="mb-2 dark-bluish quicksand-bold">
                              After the admission form is submitted, our review team will review the application and contact you incase of any queries or additional information needed.
                              Our review process involves several steps, so thank you for your patience.
                           </p>
                        </Accordion.Content>
                     </Accordion.Panel>
                  </Accordion>
               </p>
            </div>
            <div className='w-[80%] mobile-admission mx-auto my-20'>
               <h2 className='text-center text-[5vmin] text-[#EF6F56] exo-2-bold m-6'>Documents Required</h2>
               <List className=''>
                  <List.Item icon={HiCheckCircle} className='playfair-display tracking-wide text-[#37474f]'>Two passport-size photographs.</List.Item>
                  <List.Item icon={HiCheckCircle} className='playfair-display tracking-wide text-[#37474f]'>Two passport-size photographs of the father and mother each.</List.Item>
                  <List.Item icon={HiCheckCircle} className='playfair-display tracking-wide text-[#37474f]'>Copy of the child's Birth Certificate, or Aadhar card.</List.Item>
                  <List.Item icon={HiCheckCircle} className='playfair-display tracking-wide text-[#37474f]'>Copy of progress report from the previous school (Applicable for grades KG onwards).</List.Item>
                  <List.Item icon={HiCheckCircle} className='playfair-display tracking-wide text-[#37474f]'>Transfer certificate if applicable.</List.Item>
               </List>
            </div>
            <div className='mx-auto border-2 border-red-600 contact-us-container my-20'>
               <div className="circle">Contact Us</div>
               <div className="flex flex-col justify-center w-[60vmin] mobile-form mx-auto my-6 shadow-2xl bg-white">
                  <form ref={form} onSubmit={sendEmail} className="flex flex-col w-[100%] gap-4 p-11">
                     <div>
                        <div className="mb-2 block">
                           <Label htmlFor="name" value="Your Name" />
                        </div>
                        <TextInput id="name" name="user_name" type="text" placeholder="Enter Your Name" required shadow />
                     </div>
                     <div>
                        <div className="mb-2 block">
                           <Label htmlFor="email2" value="Your Email Id" />
                        </div>
                        <TextInput id="email2" name="user_email" type="email" placeholder="Enter Your Email Id" required shadow />
                     </div>
                     <div>
                        <div className="mb-2 block">
                           <Label htmlFor="enquiry" value="Your enquiry" />
                        </div>
                        <TextInput id="enquiry" value="admission" readOnly name="user_enquiry" type="text" placeholder="admission" required shadow />
                     </div>
                     <div>
                        <div className="mb-2 block">
                           <Label htmlFor="message" value="Your Message" />
                        </div>
                        <Textarea id="message" name="message" placeholder="Enter Your Message Here..." required rows={4} />
                     </div>
                     <div className="w-[100%] flex justify-center">
                            <input type="submit" value="Submit" className="w-52 h-12 rounded-md cursor-pointer text-white montserrat bg-[#EF6F56] hover:scale-80 hover:-translate-y-1 hover_state mx-auto" />
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </>
   )
}