import { useEffect, useState } from "react";
import PageHeader from "../../components/pageHeader";
import { HiCalendar } from "react-icons/hi";
import { Tabs, ListGroup } from "flowbite-react";
import store from "../../firebase";
import { collection, getDocs, query, where, Timestamp } from "firebase/firestore";
import ArchivedEvents from "../../components/archivedEvents";
import "./events.css"

const eventUpdates = collection(store, 'updates');
const ninetyDaysAgo = Timestamp.fromDate(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));

export default function EventsPage() {
    const [event, setEvents] = useState([]);

    // useEffect(() => {
    //     const queue = query(eventUpdates, where('status', '==', 'true'), where('date', '>=', ninetyDaysAgo));
    //     const unsub = onSnapshot(queue, (querySnapshot) => {
    //         const items = [];
    //         querySnapshot.forEach((values) => {

    //             items.push(values.data())
    //         })
    //         items.sort(function (a, b) { return b.date - a.date });
    //         setEvents(items);
    //     });
    //     return () => {
    //         unsub();
    //     }
    // }, [])

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(eventUpdates, where('date', '>=', ninetyDaysAgo));
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort(function (a, b) { return a.date - b.date });
                setEvents(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])

    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Events"} />
            </div>
            <div className="overflow-x-auto mt-2 pb-12 mx-6">
                <Tabs aria-label="Default tabs" variant="default" className="custom_ring_color">
                    <Tabs.Item active title="Archived Events">
                        <div className="flex flex-wrap gap-16 mobile-gap justify-center">
                            <ArchivedEvents />
                        </div>
                    </Tabs.Item>
                    <Tabs.Item title="Upcoming Events">
                        <div className="">
                            <ListGroup className="w-full sm:w-[50%] lg:w-1/2 p-4 border-none mobile-upcoming-events">
                                {event.map((eventItem, eventKey) => <ListGroup.Item key={eventKey} className="event-list-item border-b-2 m-4 shadow-xl border-[#EF6F56]">
                                    <div className="flex flex-row justify-center w-[100%] border-b-4 border-[#f5f5f5]">
                                        <h2 className="p-4 teal tracking-wider exo-2-bold  text-[20px]">{eventItem.name.toUpperCase()}</h2>
                                    </div>
                                    <p className="flex justify-start items-center w-[100%] dark-bluish p-2 montserrat-bold"><HiCalendar className="w-6 h-4 soft-red" />{eventItem.date.toDate().toDateString()}</p>
                                    <p className="flex justify-start w-[100%] text-justify dark-bluish p-4 quicksand">{eventItem.description}</p>
                                </ListGroup.Item>
                                )}
                            </ListGroup>
                        </div>
                    </Tabs.Item>
                </Tabs>
            </div>
        </>
    )
}