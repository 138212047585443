import * as React from 'react';
import { useEffect, useState } from 'react';
import db from '../firebase';
import { collection, getDocs, query } from 'firebase/firestore';

const studentProdigies = collection(db, 'student_prodigies');

export default function Academics() {
    const [studentData, setStudentData] = useState([]);

    const rankToNumber = (rank) => {
        if (!rank) return Infinity; // For handling null or undefined ranks
        return parseInt(rank.replace('st', '').replace('nd', '').replace('rd', '').replace('th', ''));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(studentProdigies);
                const querySnapshot = await getDocs(q);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort((a, b) => b.date - a.date);
                setStudentData(items);
            } catch (error) {
                console.error("error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    // Filtered data
    const twelfthToppers = studentData.filter(student => student.student_class === '12th' && student.student_rank)
        .sort((a, b) => rankToNumber(a.student_rank) - rankToNumber(b.student_rank));
    const eleventhToppers = studentData.filter(student => student.student_class === '11th' && student.student_rank)
        .sort((a, b) => rankToNumber(a.student_rank) - rankToNumber(b.student_rank));
    const tenthToppers = studentData.filter(student => student.student_class === '10th' && student.student_rank)
        .sort((a, b) => rankToNumber(a.student_rank) - rankToNumber(b.student_rank));
    const twelfthSubjectToppers = studentData.filter(student => student.student_class === '12th' && !student.student_rank);
    const eleventhSubjectToppers = studentData.filter(student => student.student_class === '11th' && !student.student_rank);
    const tenthSubjectToppers = studentData.filter(student => student.student_class === '10th' && !student.student_rank);

    return (
        <div className="">
            <section>
                <h2 className="text-[5vmin] text-center exo-2-bold tracking-wider teal mb-4">Achievers in 12th Grade
                </h2>
                <div className="mx-auto p-4">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                        {twelfthToppers.length > 0 ? twelfthToppers.map((student, index) => (
                            <div key={index} className="relative bg-white shadow-lg rounded-lg p-4">
                                <div className="absolute top-2 right-2 w-12 h-12 bg-[#EF6F56] text-white text-center montserrat-bold tracking-wider flex items-center justify-center rounded-full">
                                    {student.student_rank}
                                </div>

                                {/* Student Image */}
                                <div className="flex justify-center">
                                    <img
                                        src={student.imageUrl}
                                        alt={`${student.student_name}'s profile`}
                                        className="w-48 h-48 object-cover rounded-full"
                                    />
                                </div>

                                {/* Score */}
                                <div className="text-center mt-4">
                                    <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-teal-600">{student.total_score + "/600"}</p>
                                </div>

                                {/* Name */}
                                <div className="text-center mt-2">
                                    <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider">{student.student_name}</p>
                                </div>

                                {/* Subject Scores */}
                                <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                    {Array.isArray(student.subjectHighScores) ? (
                                        student.subjectHighScores.map((score, index) => (
                                            <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm">No Subject Data</p>
                                    )}
                                </div>
                            </div>
                        )) : <p>No data available</p>}
                    </div>
                </div>
            </section>
            <section className="mt-8 mb-8">
                <h2 className="text-[3vmin] text-center teal exo-2-bold tracking-wider mb-4">12th Grade Best in Subjects  </h2>
                <div className="mx-auto p-4">
                    <div className=''>
                        <div className={"grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4"}>                            {twelfthSubjectToppers.length > 0 ? twelfthSubjectToppers.map((student, index) => (
                            <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                                {/* Student Image */}
                                <div className="flex justify-center">
                                    <img
                                        src={student.imageUrl}
                                        alt={`${student.student_name}'s profile`}
                                        className="w-48 h-48 rounded-full object-cover"
                                    />
                                </div>

                                {/* Name */}
                                <div className="text-center mt-2">
                                    <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider">{student.student_name}</p>
                                </div>

                                {/* Subject Scores */}
                                <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                    {Array.isArray(student.subjectHighScores) ? (
                                        student.subjectHighScores.map((score, index) => (
                                            <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm">No Subject Data</p>
                                    )}
                                </div>
                            </div>
                        )) : <p>No data available</p>}
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <h2 className="text-[5vmin] text-center exo-2-bold tracking-wider teal mb-4">Achievers in 11th Grade
                </h2>
                <div className="mx-auto p-4">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                        {eleventhToppers.length > 0 ? eleventhToppers.map((student, index) => (
                            <div key={index} className="relative bg-white shadow-lg rounded-lg p-4">
                                {/* Rank Circle at the top right */}
                                <div className="absolute top-2 right-2 w-12 h-12 bg-[#EF6F56] text-white text-center montserrat-bold tracking-wider flex items-center justify-center rounded-full">
                                    {student.student_rank}
                                </div>

                                {/* Student Image */}
                                <div className="flex justify-center">
                                    <img
                                        src={student.imageUrl}
                                        alt={`${student.student_name}'s profile`}
                                        className="w-48 h-48 object-cover rounded-full"
                                    />
                                </div>

                                {/* Score */}
                                <div className="text-center mt-4">
                                    <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-teal-600">{student.total_score + "/600"}</p>
                                </div>

                                {/* Name */}
                                <div className="text-center mt-2">
                                    <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider">{student.student_name}</p>
                                </div>

                                {/* Subject Scores */}
                                <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                    {Array.isArray(student.subjectHighScores) ? (
                                        student.subjectHighScores.map((score, index) => (
                                            <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm">No Subject Data</p>
                                    )}
                                </div>
                            </div>
                        )) : <p>No data available</p>}
                    </div>
                </div>
            </section>

            <section className="mt-8 mb-8">
                <h2 className="text-[3vmin] text-center teal exo-2-bold tracking-wider mb-4">11th Grade Best in Subjects  </h2>
                <div className="mx-auto p-4">
                    <div className=''>
                        <div className={"grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4"}>                            {eleventhSubjectToppers.length > 0 ? eleventhSubjectToppers.map((student, index) => (
                            <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                                {/* Student Image */}
                                <div className="flex justify-center">
                                    <img
                                        src={student.imageUrl}
                                        alt={`${student.student_name}'s profile`}
                                        className="w-48 h-48 rounded-full object-cover"
                                    />
                                </div>

                                {/* Name */}
                                <div className="text-center mt-2">
                                    <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider">{student.student_name}</p>
                                </div>

                                {/* Subject Scores */}
                                <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                    {Array.isArray(student.subjectHighScores) ? (
                                        student.subjectHighScores.map((score, index) => (
                                            <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm">No Subject Data</p>
                                    )}
                                </div>
                            </div>
                        )) : <p>No data available</p>}
                        </div>
                    </div>
                </div>
            </section>
           

            <section>
                <h2 className="text-[5vmin] text-center exo-2-bold tracking-wider teal mb-4">Achievers in 10th Grade
                </h2>
                <div className="mx-auto p-4">
                    <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4">
                        {tenthToppers.length > 0 ? tenthToppers.map((student, index) => (
                            <div key={index} className="relative bg-white shadow-lg rounded-lg p-4">
                                {/* Rank Circle at the top right */}
                                <div className="absolute top-2 right-2 w-12 h-12 bg-[#EF6F56] text-white text-center montserrat-bold tracking-wider flex items-center justify-center rounded-full">
                                    {student.student_rank}
                                </div>

                                {/* Student Image */}
                                <div className="flex justify-center">
                                    <img
                                        src={student.imageUrl}
                                        alt={`${student.student_name}'s profile`}
                                        className="w-48 h-48 object-cover rounded-full"
                                    />
                                </div>

                                {/* Score */}
                                <div className="text-center mt-4">
                                    <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-teal-600">{student.total_score + "/500"}</p>
                                </div>

                                {/* Name */}
                                <div className="text-center mt-2">
                                    <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider font-semibold">{student.student_name}</p>
                                </div>

                                {/* Subject Scores */}
                                <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                    {Array.isArray(student.subjectHighScores) ? (
                                        student.subjectHighScores.map((score, index) => (
                                            <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                            </div>
                                        ))
                                    ) : (
                                        <p className="text-sm">No Subject Data</p>
                                    )}
                                </div>
                            </div>
                        )) : <p>No data available</p>}
                    </div>
                </div>
            </section>


            <section className="mt-8 mb-8">
                <h2 className="text-[3vmin] text-center teal exo-2-bold tracking-wider mb-4">10th Grade Best in Subjects  </h2>
                <div className="mx-auto p-4">
                    <div className=''>
                        <div className={"grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4"}>
                            {tenthSubjectToppers.length > 0 ? tenthSubjectToppers.map((student, index) => (
                                <div key={index} className="bg-white shadow-lg rounded-lg p-4">
                                    {/* Student Image */}
                                    <div className="flex justify-center">
                                        <img
                                            src={student.imageUrl}
                                            alt={`${student.student_name}'s profile`}
                                            className="w-48 h-48 rounded-full object-cover"
                                        />
                                    </div>

                                    {/* Name */}
                                    <div className="text-center mt-2">
                                        <p className="text-[3vmin] capitalize teal exo-2-bold tracking-wider">{student.student_name}</p>
                                    </div>

                                    {/* Subject Scores */}
                                    <div className="flex flex-wrap justify-around mt-4 border-t pt-2">
                                        {Array.isArray(student.subjectHighScores) ? (
                                            student.subjectHighScores.map((score, index) => (
                                                <div key={index} className={`pr-2 flex flex-col items-center ${index < student.subjectHighScores.length - 1 ? '' : ''}`}>
                                                    <p className="text-[2.5vmin] capitalize montserrat teal text-center">{score.subject}</p>
                                                    <p className="text-[4vmin] soft-red montserrat-bold tracking-wide text-center">{score.score}</p>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-sm">No Subject Data</p>
                                        )}
                                    </div>
                                </div>
                            )) : <p>No data available</p>}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
