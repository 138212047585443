import Logo from '../assets/logo_white.png';
import Star from '../assets/star.png';
import BriefAbout from '../components/briefAbout';
import Events from '../components/timeline';
import Testimonials from '../components/testimonials';
import FloatBtn from '../assets/float.png';
import Marquee from '../components/marquee';
import ShortGallery from '../components/shortGallery';
import SchoolNumbers from '../components/schoolNumbers';
import SchoolVideo from '../assets/video.mp4';
import Video from '../assets/video.webm';

export default function Home() {
    return(
        <>
        <div className='mb-6 flex flex-col'>
            <div className="header h-[100vmin] relative">
                <video autoPlay="autoplay" playsInline="playsinline" loop={true} muted={true} className='h-full w-full sm:h-full xl:h-[100vmin] xl:w-full 2xl:h-[100vmin] 2xl:w-full object-cover'>
                    <source src={Video} 
                        type='video/webm;codecs="vp8, vorbis"' />
                    <source src={SchoolVideo} 
                        type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"' />
                Your browser does not support the video tag.
                </video>
                <div className="home_layer">
                    <div className='flex flex-col items-center justify-center h-full'>
                        <img src={Logo} alt='logo' className='h-[40vmin] w-[50vmin] mobile_logo'/>
                        <h1 className='text-white header_text m-9 text-center playfair-display'>C.S.I Bishop Corrie A.I Higher Secondary School </h1>
                        <p className='normal_view text-white subhead_text flex flex-row montserrat'>
                            <span className=''>Experience</span>
                            <img src={Star} alt='star' className='mx-2 h-[7vmin] w-[7vmin] mobile_star' />
                            <span className=''>Exposure</span>
                            <img src={Star} alt='star' className='mx-2 h-[7vmin] w-[7vmin] mobile_star'/>
                            <span className=''>Excellence</span>
                        </p>
                    </div>
                </div>
            </div>
            <div>
                <Marquee />
            </div>
            <div className='mt-8'>
                <BriefAbout />
            </div>
            <div className='mt-10 mb-10'>
                <SchoolNumbers />
            </div>
            <div>
                <ShortGallery />
            </div>
            <div className=''>
                <Events />
            </div>
            <div>
                <Testimonials />
            </div>
        </div>
        <div className='sticky_button'>
                <a href="/contact" className='float'>
                    <img src={FloatBtn} alt='float' title='Contact Us' className='w-10 h-10 my-float'/>
                </a>
            </div>
        </>
    );
}