import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import mapPoint from '../assets/location-pin.png';

export default function MapBox() {
    const position = [13.102751226116116, 80.28620533530837];

    const customIcon = new Icon({
        iconUrl: mapPoint,
        iconSize: [38, 38]
    })
    const handleMarkerClick = () => {
        const a = document.createElement('a');
        a.href = "https://www.google.com/maps/dir//1,+Broadway+Rd,+Bundar+Rama+Garden,+George+Town,+Chennai,+Tamil+Nadu+600001/@13.1026017,80.2038755,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3a526f5ce22b8615:0x8c43f99ae9f898b6!2m2!1d80.2862774!2d13.1026148?entry=ttu&g_ep=EgoyMDI0MDgyNy4wIKXMDSoASAFQAw%3D%3D";
        a.target = "_blank";
        a.rel = "noopener noreferrer";
        a.click();
    };

    return(
        <div className="">
            <MapContainer  className='map-container' center={position} zoom={13}>
                <TileLayer 
                    attribution='<a href="https://www.openstreetmap.org/copyright"></a>'
                    url="https://tile.thunderforest.com/atlas/{z}/{x}/{y}.png?apikey=fb1f938fdda1438d8a7ea982d8e57db4"
                />
                <Marker eventHandlers={{ click: handleMarkerClick }} position={position} icon={customIcon}>
                    <Popup>C.S.I Bishop Corrie A.I Higher Secondary School</Popup>
                </Marker>
            </MapContainer>
        </div>
    )
}