import PageHeader from "../../components/pageHeader";
import { Card } from "flowbite-react";
import './staff.css';
import abraham from "../../assets/teachers/abraham.JPG"
import ammu from "../../assets/teachers/ammu.JPG"
import angeline from "../../assets/teachers/angeline.JPG"
import caroline from "../../assets/teachers/caroline.JPG"
import jemima from "../../assets/teachers/jemima.JPG"
import kennedy from "../../assets/teachers/kennedy.JPG"
import leena from "../../assets/teachers/leena.JPG"
import lydia from "../../assets/teachers/lydia.JPG"
import olivia from "../../assets/teachers/olivia.JPG"
import pradeep from "../../assets/teachers/pradeep.JPG"
import priya from "../../assets/teachers/priya.JPG"
import rajini from "../../assets/teachers/rajini.JPG"
import rebecca from "../../assets/teachers/rebecca.JPG"
import solomon from "../../assets/teachers/solomon.JPG"
import thabitha from "../../assets/teachers/thabitha.JPG"
import genevieve from "../../assets/teachers/genevieve.JPG"
import tamilmani from "../../assets/teachers/tamilmani.JPG"
import alice from "../../assets/teachers/alice.JPG"                     //not there
import alvin from "../../assets/teachers/alvin.JPG"
import arun from "../../assets/teachers/arunprasad.JPG"
import beulahJ from "../../assets/teachers/beulahJ.JPG"
import charmine from "../../assets/teachers/charmine.JPG"
import daisy from "../../assets/teachers/daisy.JPG"
import deivanai from "../../assets/teachers/deivanai.JPG"
import devakirubai from "../../assets/teachers/devakirubai.JPG"
import dinesh from "../../assets/teachers/dinesh.JPG"
import florence from "../../assets/teachers/florencematilda.JPG"
import irudayaraj from "../../assets/teachers/irudayaraj.JPG"
import jacky from "../../assets/teachers/jacky.JPG"
import jenifer from "../../assets/teachers/jenifer.JPG"
import kalpana from "../../assets/teachers/kalpana.JPG"
import mary from "../../assets/teachers/marykernab.JPG"
import praba from "../../assets/teachers/praba.JPG"
import priscilla from "../../assets/teachers/priscilla.JPG"
import ramesh from "../../assets/teachers/ramesh.JPG"
import reena from "../../assets/teachers/reena.JPG"
import sambabu from "../../assets/teachers/sambabu.JPG"
import samdriver from "../../assets/teachers/samdriver.JPG"
import usha from "../../assets/teachers/usha.JPG"
import vanitha from "../../assets/teachers/vanitha.JPG"
import pushparaj from "../../assets/teachers/pushparaj.JPG"
import random from "../../assets/random_person.png"

export default function Staff() {
    return (
        <>
            <div className="soft-red-bg px-4">
                <PageHeader title={"Meet Our Staffs"} />
            </div>
            <div className="hide_staff">
                <Card className="w-[80%] mr-auto ml-auto my-4 shadow-xl">
                <h1 className="text-center montserrat-bold tracking-wide soft-red text-[4vmin]">PG Assistants</h1>

                    <div className="flow-root">
                        <ul className="divide-y divide-gray-200 dark:divide-gray-700">
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image1"
                                            height="32"
                                            src={kennedy}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Anthony Kennedy.K</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="flex flex-col items-end">
                                        <div className="inline-flex items-center soft-red text-[3vmin] montserrat-bold tracking-wide">Assistant HeadMaster</div>
                                        <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">Maths</div>
                                    </div>
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image2"
                                            height="32"
                                            src={abraham}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Abraham Jai Singh.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed., M.Phil.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Physics
                                    </div>
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image3"
                                            height="32"
                                            src={solomon}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. John Solomon.B.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">Biology</div>
                                </div>
                            </li>
                            <li className="py-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image4"
                                            height="32"
                                            src={pradeep}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. H.Pradeep Celestine Charles</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Com., M.Phil., B.Ed., M.B.A.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">Commerce & Accounts</div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={arun}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Arun Prasath</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Ed., M.Phil.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Tamil
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={priscilla}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Priscilla Jasmine. D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Phil., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Economics
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image5"
                                            height="32"
                                            src={olivia}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Olivia Vasanthi.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">B.Sc., M.C.A., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Computer Science
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center pt-8 pb-8 montserrat-bold tracking-wide soft-red text-[4vmin]">BT Assistants</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image6"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Gladys Thabitha.C</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        English
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image7"
                                            height="32"
                                            src={lydia}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Lydia Padmini.S</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Social Science
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image8"
                                            height="32"
                                            src={leena}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Leena.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">B.Sc., B.Ed., M.Sc.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Physics
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image9"
                                            height="32"
                                            src={caroline}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Caroline Devakirubai.A</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Maths
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={sambabu}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Sambabu.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Phil., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Tamil
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={dinesh}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Dinesh.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Science
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={irudayaraj}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Irudayaraj.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        History
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold py-8 tracking-wide soft-red text-[4vmin]">Secondary Grade Assistants</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Shalet Devamanohari.A</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image10"
                                            height="32"
                                            src={charmine}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Charmine Joseph</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., D.T.Ed., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image11"
                                            height="32"
                                            src={angeline}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Angelin Sopiah.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed., B.Lit.(Tamil), B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image12"
                                            height="32"
                                            src={genevieve}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Genevieve Maria Cockburn</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image13"
                                            height="32"
                                            src={jemima}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Jemima Shirley Shobana.R</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image14"
                                            height="32"
                                            src={priya}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Beluah Priya Dharisini.H</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image15"
                                            height="32"
                                            src={thabitha}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Thabitha Victoria Getsia.S</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed., M.A., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image17"
                                            height="32"
                                            src={rebecca}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Rebecca Maria Sam</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">B.A, D.T.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image18"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Beulah.S</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed., M.A., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={usha}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Usha.S</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">D.T.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Primary School
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Nishkala.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">B.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
Primary School
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold tracking-wide py-8 soft-red text-[4vmin]">Physical Education Department</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image19"
                                            height="32"
                                            src={ammu}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Ammu Christial.P</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Com., C.P.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Physical Education
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image20"
                                            height="32"
                                            src={rajini}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Rajini Jebakumar.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.P.Ed., M.Phil.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Physical Education
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={alvin}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Alwin.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.P.Ed., M.Phil.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Physical Education
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold tracking-wide py-8 soft-red text-[4vmin]">Arts and Crafts</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image21"
                                            height="32"
                                            src={pushparaj}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Pushparaj.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">T.E.C., T.T.C., B.A.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Arts
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold tracking-wide py-8 soft-red text-[4vmin]">Management Staffs</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={florence}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Florence Matlida</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.Sc., B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Computer Science
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={vanitha}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Vanitha</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Tamil
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={reena}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Reena.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">M.A., M.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        English
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={beulahJ}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Beulah.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        SGA Computer Asst
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={tamilmani}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Thamizmani</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Computer Asst
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold tracking-wide py-8 soft-red text-[4vmin]">Part Time Teacher</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={kalpana}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Kalpana</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide">B.Tech, B.Ed.,</p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        French
                                    </div>
                                </div>
                            </li>
                            <h1 className="text-center montserrat-bold tracking-wide py-8 soft-red text-[4vmin]">Non Teaching Staffs</h1>

                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image22"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Pushparaj.P</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Junior Assistant
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image23"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Sathya Prakash Edward.E</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Record Clerk
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image24"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Ms. Premkumari.J</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Lab Assistant
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image25"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Richard Umbrand.D</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Junior Assistant (MGT)
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image26"
                                            height="32"
                                            src={deivanai}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Daivanai.K</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Office Assistant
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image27"
                                            height="32"
                                            src={random}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mrs. Vajramma.K</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Sweeper
                                    </div>
                                </div>
                            </li>
                            <li className="pb-0 pt-3 sm:py-4">
                                <div className="flex items-center space-x-4">
                                    <div className="shrink-0">
                                        <img
                                            alt="image16"
                                            height="32"
                                            src={ramesh}
                                            width="32"
                                            className="rounded-full"
                                        />
                                    </div>
                                    <div className="min-w-0 flex-1">
                                        <p className="truncate text-[2.5vmin] dark-bluish exo-2-bold">Mr. Ramesh</p>
                                        <p className="truncate teal text-[2vmin] quicksand tracking-wide"></p>
                                    </div>
                                    <div className="inline-flex items-center text-[2.5vmin] teal montserrat tracking-wide">
                                        Waterman
                                    </div>
                                </div>
                            </li>
                            
                        </ul>
                    </div>
                </Card>
            </div>
            <div className="flex flex-col mx-auto gap-y-2 my-6 show_staff">
                <h1 className="text-center montserrat-bold tracking-wide soft-red text-[4vmin]">PG Assistants</h1>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="64"
                            src={kennedy}
                            // width="64"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Anthony Kennedy.K</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center soft-red montserrat-bold text-[3vmin]">Assistant HeadMaster</div>
                        <div className="inline-flex teal items-center text-[3vmin] montserrat">Maths</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={abraham}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Abraham Jai Singh.D</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed., M.Phil.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Physics</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={solomon}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. John Solomon.B.J</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Biology</div>
                    </div>
                </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={pradeep}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 max-w-48">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. H.Pradeep Celestine Charles</p>
                        <p className="text-[3vmin] teal quicksand">M.Com., M.Phil., B.Ed., M.B.A.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Commerce & Accounts</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={arun}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Arun Prasath</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Ed., M.Phil.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Tamil
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={priscilla}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Priscilla Jasmine. D</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Phil., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Economics
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={olivia}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Olivia Vasanthi.D - (Mgt)</p>
                        <p className="text-[3vmin] teal quicksand">B.Sc., M.C.A., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Computer Science</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={kalpana}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Kalpana - (Mgt)</p>
                        <p className="text-[3vmin] teal quicksand">B.Tech., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        French
                    </div>
                    </div>
                </div>
                <h1 className="text-center montserrat-bold tracking-wide mt-8 mb-4 soft-red text-[4vmin]">BT Assistants</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Gladys Thabitha.C</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">English</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={lydia}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Lydia Padmini.S</p>
                        <p className="text-[3vmin] teal quicksand">M.A., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Social Science</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={leena}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Leena.J</p>
                        <p className="text-[3vmin] teal quicksand">B.Sc., B.Ed., M.Sc.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Physics</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={caroline}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Caroline Devakirubai.A</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Maths</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={sambabu}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Sambabu.J</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Phil., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Tamil
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={dinesh}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Dinesh.D</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Science
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={irudayaraj}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Irudayaraj.J</p>
                        <p className="text-[3vmin] teal quicksand">M.A., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        History
                    </div>
                    </div>
                </div>
                <h1 className="text-center montserrat-bold tracking-wide mt-8 mb-4 soft-red text-[4vmin]">Seondary Grade Assistants</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Shalet Devamanohari.A</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={charmine}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Charmine Joseph</p>
                        <p className="text-[3vmin] teal quicksand">M.A., D.T.Ed., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={angeline}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Angelin Sopiah.J</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed., B.Lit.(Tamil), B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={genevieve}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Genevieve Maria Cockburn</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={jemima}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Jemima Shirley Shobana.R</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={priya}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Beluah Priya Dharisini.H</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={thabitha}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Thabitha Victoria Getsia.S</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed., M.A., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={rebecca}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Rebecca Maria Sam</p>
                        <p className="text-[3vmin] teal quicksand">B.A., D.T.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Beulah.S</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed., M.A., B.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Primary School</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={usha}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Usha.S</p>
                        <p className="text-[3vmin] teal quicksand">D.T.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Primary School
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Nishkala.J</p>
                        <p className="text-[3vmin] teal quicksand">B.Sc., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
        Primary School
                    </div>
                    </div>
                </div>
                <h1 className="text-center montserrat-bold tracking-wide soft-red mt-8 mb-4 text-[4vmin]">Physical Education Department</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={ammu}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Ammu Christial.P</p>
                        <p className="text-[3vmin] teal quicksand">M.Com., C.P.Ed.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Physical Education</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={rajini}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Rajini Jebakumar.J</p>
                        <p className="text-[3vmin] teal quicksand">M.P.Ed., M.Phil.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Physical Education</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={alvin}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Alwin.D</p>
                        <p className="text-[3vmin] teal quicksand">M.PEd., M.Phil.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Physical Education
                    </div>
                    </div>
                </div>
                <h1 className="text-center montserrat-bold tracking-wide soft-red mt-8 mb-4 text-[4vmin]">Arts and Crafts</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={pushparaj}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Pushparaj.D</p>
                        <p className="text-[3vmin] teal quicksand">T.E.C., T.T.C., B.A.,</p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Arts</div>
                    </div>
                    </div>
                </div>
                <h1 className="text-center montserrat-bold tracking-wide soft-red mt-8 mb-4 text-[4vmin]">Management Staffs</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={florence}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Florence Matlida</p>
                        <p className="text-[3vmin] teal quicksand">M.Sc., B.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Computer Science
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={vanitha}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Vanitha</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Tamil
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={reena}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Reena.J</p>
                        <p className="text-[3vmin] teal quicksand">M.A., M.Ed.,</p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        English
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={beulahJ}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Beulah.J</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        SGA Computer Asst
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={tamilmani}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Thamizmani</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Computer Asst
                    </div>
                    </div>
                </div>
                
                <h1 className="text-center montserrat-bold tracking-wide soft-red mt-8 mb-4 text-[4vmin]">Non Teaching Staffs</h1>

                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Pushparaj.P</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Junior Assistant</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Sathya Prakash Edward.E</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Record Clerk</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Ms. Premkumari.J</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Lab Assistant</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Richard Umbrand.D</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Junior Assistant (MGT)</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={deivanai}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Daivanai.K</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Office Assistant</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image1"
                            // height="32"
                            src={random}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mrs. Vajramma.K</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="flex flex-col">
                        <div className="inline-flex items-center teal text-[3vmin] montserrat">Sweeper</div>
                    </div>
                    </div>
                </div>
                <div className="flex p-4 gap-y-2 shadow-lg">
                    <div className="shrink-0 h-[30vmin] w-[30vmin] rounded-full overflow-hidden">
                        <img
                            alt="image16"
                            // height="32"
                            src={ramesh}
                            // width="32"
                            className="w-full h-full object-cover"
                        />
                    </div>
                    <div className="flex flex-col ml-10 items-start justify-evenly">
                    <div className="min-w-0 ">
                        <p className="text-[3vmin] dark-bluish montserrat-bold">Mr. Ramesh</p>
                        <p className="text-[3vmin] teal quicksand"></p>
                    </div>
                    <div className="inline-flex items-center teal text-[3vmin] montserrat">
                        Waterman
                    </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}